var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad-container" },
    [
      _c(
        "v-card",
        { staticClass: "reg-form", attrs: { width: "600" } },
        [
          _c(
            "v-form",
            {
              ref: "regForm",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.setUpPassword.apply(null, arguments)
                },
              },
            },
            [
              _c("v-card-title", [_vm._v("Set up password")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "label-form" }, [_vm._v("Email")]),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-at",
                      clearable: "",
                      rules: [_vm.rules.email, _vm.rules.required],
                    },
                    model: {
                      value: _vm.userReg.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.userReg, "email", $$v)
                      },
                      expression: "userReg.email",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Username"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "prepend-inner-icon": "mdi-account",
                      clearable: "",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.userReg.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.userReg, "username", $$v)
                      },
                      expression: "userReg.username",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Create Password"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "append-icon": _vm.showPass1 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass1 ? "text" : "password",
                      "prepend-inner-icon": "mdi-account-key",
                      rules: [_vm.rules.required, _vm.rules.counter],
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass1 = !_vm.showPass1
                      },
                    },
                    model: {
                      value: _vm.userReg.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.userReg, "password", $$v)
                      },
                      expression: "userReg.password",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Confirm Password"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      dense: "",
                      "append-icon": _vm.showPass2 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass2 ? "text" : "password",
                      "prepend-inner-icon": "mdi-account-key",
                      rules: _vm.confirmPasswordRules.concat(
                        _vm.passwordConfirmationRule
                      ),
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass2 = !_vm.showPass2
                      },
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function ($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "confirm",
                        dark: "",
                        width: "100",
                        type: "submit",
                        disabled: _vm.btnDisable,
                      },
                    },
                    [_vm._v("Register")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import Keycloak from "keycloak-js";
import IdleVue from "idle-vue";
import swal from "sweetalert";
Vue.prototype.moment = moment;
Vue.config.productionTip = false;

const BASE_URL = `https://${process.env.VUE_APP_FQDN}`;

axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let message = "";
    if (error.response.data.error?.error_details) {
      message = `${error.response.data.error.message}. ${error.response.data.error.error_details[0]}`;
    } else {
      message =
        error.response.data.error ||
        error.response.data.message ||
        "Internal Server Error. Please contact an administrator.";
    }
    swal("Error", message, "error");
    return Promise.reject(error);
  }
);

let initOptions = {
  url: BASE_URL + "/auth",
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_FRONTEND_CLIENT_NAME,
};

let keycloak = new Keycloak(initOptions);
axios
  .get("/api/common/v1.1.0/health_check")
  .then((res) => {
    if (res.data.create_first_user) router.push({ name: "RegisterView" });
    else {
      keycloak
        .init({ onLoad: "login-required", checkLoginIframe: false })
        .then((authenticated) => {
          store.commit("setKeycloakHandler", keycloak);
          store.commit("setToken", keycloak.refreshToken);
          store.commit("setUuid", keycloak.subject);
          store.commit("setUserEmail", keycloak.tokenParsed.email);
          //Avoid task worker user to log into the admin UI
          if (
            keycloak.idTokenParsed.preferred_username ===
              process.env.VUE_APP_WORKER ||
            keycloak.idTokenParsed.preferred_username === "fair"
          ) {
            authenticated = false;
          }

          if (authenticated) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${keycloak.refreshToken}`;
          } else {
            store.dispatch("logOut");
          }
          //Token Refresh
          setInterval(() => {
            keycloak.updateToken(20).catch(() => {
              console.error("Failed to refresh token");
            });
          }, 60000);
        })
        .catch((err) => {
          console.error(err);
          console.error("Authenticated Failed");
        });
    }
  })
  .catch((err) => {
    console.log(err);
  });

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1200000, // modify time here 1 minute = 60000 / 20 minutes = 1200000
  startAtIdle: false,
});

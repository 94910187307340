var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-6 mt-3 webhooktable",
        attrs: {
          headers: _vm.webHookHeaders,
          items: _vm.webHook,
          "hide-default-footer": true,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { color: "primary", dark: "" } },
                  [
                    _c(
                      "v-toolbar-title",
                      [
                        _vm._v("Data Access Requests Web Hook Configuration "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { right: "", "max-width": "250" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [_vm._v("mdi-information")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.description))])]
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", {
                      staticClass: "mx-4",
                      attrs: { inset: "", vertical: "" },
                    }),
                    _c("v-spacer"),
                    _vm.webHook.length === 0
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mb-2",
                            attrs: { color: "#fff", outlined: "" },
                            on: {
                              click: function ($event) {
                                _vm.webHookModal = true
                              },
                            },
                          },
                          [_vm._v(" Add Web Hook ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.url))]),
                  _c("td", [_vm._v(_vm._s(item.created_at))]),
                  _c("td", [_vm._v(_vm._s(item.updated_at))]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            transition: "slide-x-transition",
                            bottom: "",
                            right: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b({}, "v-icon", attrs, false),
                                        on
                                      ),
                                      [_vm._v("mdi-dots-vertical")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openUpdateWebHook(item.url)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("Update")]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteWebHook(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "error" } },
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [_vm._v("Delete")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm.webHookModal
        ? _c("AddWebHookModalVue", {
            attrs: { openModal: _vm.webHookModal, rules: _vm.rules },
            on: { closeWH: _vm.closeNewWHModal, addWH: _vm.addWebHook },
          })
        : _vm._e(),
      _vm.editWebHookModal
        ? _c("EditWebHookModal", {
            attrs: { editableUrl: _vm.url, openModal: _vm.editWebHookModal },
            on: {
              updateWH: _vm.updateWebHook,
              "close-edit": _vm.closeEditWebHook,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "350", persistent: "" },
      model: {
        value: _vm.addNewIp,
        callback: function ($$v) {
          _vm.addNewIp = $$v
        },
        expression: "addNewIp",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("New IP")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  solo: "",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  placeholder: "x.x.x.x or x.x.x.x/x",
                },
                model: {
                  value: _vm.newIP,
                  callback: function ($$v) {
                    _vm.newIP = $$v
                  },
                  expression: "newIP",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", dark: "" },
                  on: { click: _vm.saveChanges },
                },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c("v-card-text", { staticClass: "px-10" }, [
        _c("div", { staticClass: "mt-5" }, [
          _c(
            "div",
            [
              _c(
                "v-card",
                { attrs: { color: "#f2f3f4" } },
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "px-4 d-flex justify-space-between" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("v-text-field", {
                              staticClass: "filter-inputs",
                              attrs: {
                                outlined: "",
                                dense: "",
                                solo: "",
                                label: "User email",
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "filter-inputs mx-3",
                              attrs: {
                                outlined: "",
                                dense: "",
                                solo: "",
                                items: _vm.stateOptions,
                                label: "State",
                              },
                              on: { change: _vm.getTasks },
                              model: {
                                value: _vm.params.current_state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "current_state", $$v)
                                },
                                expression: "params.current_state",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "filter-inputs mr-3",
                              attrs: {
                                outlined: "",
                                dense: "",
                                solo: "",
                                items: _vm.viewOptions,
                                "item-text": "title",
                                "item-value": "value",
                                label: "View",
                              },
                              on: { change: _vm.getTasks },
                              model: {
                                value: _vm.params.view,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "view", $$v)
                                },
                                expression: "params.view",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-3",
                                attrs: { color: "cancel" },
                                on: {
                                  click: function ($event) {
                                    _vm.params = {}
                                  },
                                },
                              },
                              [_vm._v("Clear")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "confirm", dark: "" },
                                on: { click: _vm.refreshTable },
                              },
                              [_vm._v("Refresh")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _c("v-data-table", {
              staticClass: "elevation-6 mt-3 mb-3",
              attrs: {
                headers: _vm.tableHeaders,
                items: _vm.filteredTasks,
                search: _vm.search,
                "items-per-page": _vm.pagination.rowsPerPage,
                page: _vm.pagination.page,
                "footer-props": {
                  itemsPerPageOptions: [5, 15, 25, 50],
                },
                "disable-sort": true,
                "server-items-length": _vm.totalItems,
              },
              on: {
                "update:itemsPerPage": function ($event) {
                  return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                },
                "update:items-per-page": [
                  function ($event) {
                    return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                  },
                  _vm.onItemsPerPageChange,
                ],
                "update:page": [
                  function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  _vm.onPageChange,
                ],
              },
              scopedSlots: _vm._u([
                {
                  key: "item.executors",
                  fn: function (props) {
                    return _vm._l(props.item.executors, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "mb-3" },
                        _vm._l(item, function (value, key) {
                          return _c("span", { key: key }, [
                            _c("b", { staticClass: "tbl-subsections mt-2" }, [
                              _vm._v(_vm._s(key) + ":"),
                            ]),
                            key === "image"
                              ? _c("span", [_vm._v(_vm._s(value))])
                              : _vm._e(),
                            key === "command"
                              ? _c(
                                  "ul",
                                  { staticClass: "mb-2" },
                                  _vm._l(value, function (cmd, index) {
                                    return _c("li", { key: index }, [
                                      _vm._v(" " + _vm._s(cmd) + " "),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ])
                        }),
                        0
                      )
                    })
                  },
                },
                {
                  key: "item.inputs",
                  fn: function (props) {
                    return [
                      _c(
                        "ul",
                        { staticClass: "input-list" },
                        _vm._l(props.item.inputs, function (input, index) {
                          return _c(
                            "span",
                            { key: index },
                            _vm._l(input, function (value, key) {
                              return _c(
                                "span",
                                { key: key, staticClass: "mb-2" },
                                [
                                  _c(
                                    "b",
                                    { staticClass: "tbl-subsections mt-2" },
                                    [_vm._v(_vm._s(key) + ":")]
                                  ),
                                  _vm._v(" " + _vm._s(value) + " "),
                                ]
                              )
                            }),
                            0
                          )
                        }),
                        0
                      ),
                    ]
                  },
                },
                {
                  key: "item.state",
                  fn: function (props) {
                    return [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            color:
                              props.item.state === "COMPLETE"
                                ? "rgb(50 175 16)"
                                : props.item.state === "QUARANTINED"
                                ? "rgb(225 201 0)"
                                : props.item.state === "REJECTED"
                                ? "#785050"
                                : props.item.state === "SYSTEM_ERROR" ||
                                  props.item.state === "EXECUTOR_ERROR"
                                ? "#da3939"
                                : "gray",
                          },
                        },
                        [_vm._v(_vm._s(props.item.state))]
                      ),
                    ]
                  },
                },
                {
                  key: "item.actions",
                  fn: function (props) {
                    return [
                      _vm.allowed
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "slide-x-transition",
                                bottom: "",
                                right: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b({}, "v-icon", attrs, false),
                                            on
                                          ),
                                          [_vm._v("mdi-dots-vertical")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  (props.item.state === "QUEUED" ||
                                    props.item.state === "INITIALIZING" ||
                                    props.item.state === "RUNNING") &&
                                  props.item.state !== "CANCELLED"
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.cancelTask(props.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-briefcase-off"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Cancel"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  props.item.state === "COMPLETE" ||
                                  props.item.state === "QUARANTINED" ||
                                  props.item.state === "EXECUTOR_ERROR"
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.getResults(props.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              props.item.state ===
                                              "EXECUTOR_ERROR"
                                                ? _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-briefcase-remove"
                                                    ),
                                                  ])
                                                : _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-briefcase-download"
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.state ===
                                                  "EXECUTOR_ERROR"
                                                  ? "Error logs"
                                                  : "Review Results"
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  props.item.state === "QUARANTINED"
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.openReview(props.item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-briefcase-eye"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Approve/Reject"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showHistory(props.item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-briefcase-eye"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("History"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.taskHistoryPopup
        ? _c("TaskHistoryModal", {
            attrs: {
              taskHistory: _vm.historyList,
              showHistory: _vm.taskHistoryPopup,
            },
            on: { "close-history": _vm.closeHistoryModal },
          })
        : _vm._e(),
      _vm.reviewTaskPopup
        ? _c("ReviewTaskModal", {
            attrs: {
              openTaskReviewModal: _vm.reviewTaskPopup,
              rules: _vm.rules,
            },
            on: {
              "close-review": _vm.closeReviewModal,
              review: _vm.reviewTask,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div>
        <div class="d-flex justify-end service-table">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!exist"
                class="mr-2"
                color="confirm"
                dark
                @click="newServicePopup = true"
                small
                v-bind="attrs"
                v-on="on"
                >UPDATE FDSA SERVICE INFO</v-btn
              >
              <v-btn
                color="warning"
                v-else
                @click="updateService"
                small
                v-bind="attrs"
                v-on="on"
                >EDIT FDSA SERVICE INFO</v-btn
              >
              <v-tooltip right class="ml-3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                </template>
                <span
                  >This information will be made available to
                  end-user/researchers <br />via API call. This will help them
                  easily understand some details <br />about the FDSA Service
                  they atr connected to during their research.</span
                >
              </v-tooltip>
            </template>
            <span
              >Allows to update the publicly available information about the
              FDSA</span
            >
          </v-tooltip>
        </div>
        <v-simple-table class="elevation-6 mt-2 mb-15 service-table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>{{ service.id }}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{{ service.name }}</td>
            </tr>
            <tr>
              <th>Organization</th>
              <td>
                <span
                  v-for="(value, name, index) in service.organization"
                  :key="index"
                >
                  <div>
                    <label class="label-form">{{ name }}: </label>
                    <span class="ml-3">{{ value }}</span>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <th>Approved Registries</th>
              <td>
                <ul
                  v-for="(ar, index) in service.approved_registries"
                  :key="index"
                >
                  <li>{{ ar }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Contact URL</th>
              <td>{{ service.contactUrl }}</td>
            </tr>
            <tr>
              <th>Documentation URL</th>
              <td>{{ service.documentationUrl }}</td>
            </tr>
            <tr>
              <th>Environment</th>
              <td>{{ service.environment }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ service.description }}</td>
            </tr>
            <tr>
              <th>Storage</th>
              <td>
                <ul v-for="(st, index) in service.storage" :key="index">
                  <li>{{ st }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>
                <span v-for="(value, name, index) in service.type" :key="index">
                  <div>
                    <label class="label-form"> {{ name }}: </label>
                    <span class="ml-3">{{ value }}</span>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <th>Created</th>
              <td>
                <span v-if="service.createdAt">
                  {{
                    moment(service.createdAt).format("D MMMM YYYY - HH:mm:ss")
                  }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Updated</th>
              <td>
                <span v-if="service.updatedAt">
                  {{
                    moment(service.updatedAt).format("D MMMM YYYY - HH:mm:ss")
                  }}
                </span>
              </td>
            </tr>
            <tr>
              <th>Version</th>
              <td>{{ service.version }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>

      <!-- Add new service info modal -->
      <NewServiceInfoModal
        @new-service="addService"
        @close-service="closeAddService"
        :rules="rules"
        :openModal="newServicePopup"
        v-if="newServicePopup"
      />

      <!-- Edit service Modal -->
      <EditServiceInfoModal
        :openModal="editServicePopup"
        :service="targetSrvc"
        @close-edit="closeEditService"
        @update="confirmUpdate"
        v-if="editServicePopup"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import NewServiceInfoModal from "@/components/dialogs/NewServiceInfoModal.vue";
import EditServiceInfoModal from "@/components/dialogs/EditServiceInfoModal.vue";
export default {
  name: "serviceSection",
  components: {
    NewServiceInfoModal,
    EditServiceInfoModal,
  },
  props: {
    admin_token: String,
    rules: Object,
  },
  data() {
    return {
      newService: {},
      service: {},
      servicetblheaders: [
        { text: "ID" },
        { text: "Name" },
        { text: "Organization" },
        { text: "Approved Regitries" },
        { text: "Contact URL" },
        { text: "Documentation URL" },
        { text: "Environment" },
        { text: "Description" },
        { text: "Storage" },
        { text: "Type" },
        { text: "Created" },
        { text: "Updated" },
        { text: "Version" },
      ],
      editServicePopup: false,
      newServicePopup: false,
      targetSrvc: {},
      exist: false,
    };
  },
  methods: {
    async getServices() {
      const service = axios.create({
        baseURL: `https://${process.env.VUE_APP_FQDN}`,
      });
      try {
        const res = await service.get("/api/common/v1.1.0/service-info");
        this.service = res.data;
        this.exist = true;
      } catch (err) {
        console.log(err);
      }
    },
    async addService(service) {
      this.$store.commit("setLoader", true);
      this.newServicePopup = false;
      try {
        const res = await axios.post("/api/admin/v1.0/service-info", service);
        console.log(res.status);
        swal("Confirmation", "Service added", "success");
        this.getServices();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    updateService() {
      this.targetSrvc = Object.assign({}, this.service);
      this.editServicePopup = true;
    },
    async confirmUpdate(srvc) {
      let payload = {};
      payload = {
        name: srvc.name,
        description: srvc.description,
        organization_name: srvc.organization.name,
        organization_url: srvc.organization.url,
        contact_url: srvc.contactUrl,
        documentation_url: srvc.documentationUrl,
      };
      this.editServicePopup = false;
      this.$store.commit("setLoader", true);

      try {
        const res = await axios.patch("/api/admin/v1.0/service-info", payload);
        console.log(res.status);
        this.getServices();
        swal("Confirmation", "Service updated", "success");
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    closeAddService(val) {
      this.newServicePopup = val;
    },
    closeEditService(val) {
      this.editServicePopup = val;
    },
  },
  mounted() {
    this.getServices();
  },
};
</script>
<style scoped>
.service-table {
  width: 900px;
  margin: 0 auto;
}
.tooltip-content {
  white-space: pre-line;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "700" },
      model: {
        value: _vm.updateDs,
        callback: function ($$v) {
          _vm.updateDs = $$v
        },
        expression: "updateDs",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Update data source "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-database-edit")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newDtSrcForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database type"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              items: _vm.databaseTypesOptions,
                              outlined: "",
                              solo: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.targetDataSource.database_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.targetDataSource,
                                  "database_type",
                                  $$v
                                )
                              },
                              expression: "targetDataSource.database_type",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Name (Friendly Name)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.targetDataSource.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.targetDataSource, "name", $$v)
                              },
                              expression: "targetDataSource.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Host (FQDN Or IPs Only)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.targetDataSource.host,
                              callback: function ($$v) {
                                _vm.$set(_vm.targetDataSource, "host", $$v)
                              },
                              expression: "targetDataSource.host",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Port"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              solo: "",
                              outlined: "",
                              dense: "",
                              min: "0",
                              rules: [_vm.rules.required],
                              disabled: "",
                            },
                            model: {
                              value: _vm.targetDataSource.port,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.targetDataSource,
                                  "port",
                                  _vm._n($$v)
                                )
                              },
                              expression: "targetDataSource.port",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database Name (Case Sensitive)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.targetDataSource.database_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.targetDataSource,
                                  "database_name",
                                  $$v
                                )
                              },
                              expression: "targetDataSource.database_name",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database User (Case Sensitive)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.notAllowedUser,
                              ],
                            },
                            model: {
                              value: _vm.targetDataSource.database_user,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.targetDataSource,
                                  "database_user",
                                  $$v
                                )
                              },
                              expression: "targetDataSource.database_user",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Description"),
                          ]),
                          _c("v-textarea", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.targetDataSource.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.targetDataSource,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "targetDataSource.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Update")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-footer absolute dark>
    <p>
      © Federated Data Sharing Appliance. Version: {{ version }} - Release date:
      {{ releaseDate }}
    </p>
  </v-footer>
</template>
<script>

export default {
  props: {
    rules: Object,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      releaseDate: process.env.VUE_APP_RELEASE_DATE,
    };
  },
};
</script>

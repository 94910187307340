var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-simple-table",
        {
          staticClass: "elevation-6 mt-2 mb-15 smtptable",
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "primary", dark: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("SMTP Settings")]),
                      _c("v-divider", {
                        staticClass: "mx-4",
                        attrs: { inset: "", vertical: "" },
                      }),
                      _c("v-spacer"),
                      _vm.smtp.length === 0
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "#fff", outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.addSmtpPopup = true
                                },
                              },
                            },
                            [_vm._v(" Configure SMTP ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "#fff", outlined: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openUpdateSMTP()
                                },
                              },
                            },
                            [_vm._v(" Update ")]
                          ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("Display Name")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.mail_display_name))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Mail port")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.mail_port))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Mail Username")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.mail_username))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Mail Server")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.mail_server))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Default Sender")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.mail_default_sender))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("SSL")]),
              _c("td", [
                _vm.smtp.mail_use_ssl
                  ? _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { color: "#0af215" } }, [
                          _vm._v("mdi-check"),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { color: "#f2190a" } }, [
                          _vm._v("mdi-close"),
                        ]),
                      ],
                      1
                    ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("TLS")]),
              _c("td", [
                _vm.smtp.mail_use_tls
                  ? _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { color: "#0af215" } }, [
                          _vm._v("mdi-check"),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { color: "#f2190a" } }, [
                          _vm._v("mdi-close"),
                        ]),
                      ],
                      1
                    ),
              ]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Created at")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.created_at))]),
            ]),
            _c("tr", [
              _c("th", [_vm._v("Updated at")]),
              _c("td", [_vm._v(_vm._s(_vm.smtp.updated_at))]),
            ]),
          ]),
        ]
      ),
      _vm.smtpPopup
        ? _c("EditSMTPModal", {
            attrs: { smtpObject: _vm.smtpEditable, openModal: _vm.smtpPopup },
            on: {
              "save-smtp": _vm.editSMTPConfig,
              "close-smtp": _vm.closeSMTPModal,
            },
          })
        : _vm._e(),
      _vm.addSmtpPopup
        ? _c("AddSMTPConfig", {
            attrs: { openModal: _vm.addSmtpPopup, rules: _vm.rules },
            on: {
              "save-smtp": _vm.editSMTPConfig,
              "close-smtp": _vm.closeAddSMTPModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog v-model="setStmpModal" width="600" persistent>
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-3">mdi-email</v-icon>
        SMTP Configuration
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <label class="label-form" for="server">Mail server</label>
        <v-text-field class="mb-2" solo outlined dense v-model="smtpConfig.mail_server" id="server"
          hide-details></v-text-field>
        <label class="label-form" for="port">Mail Port</label>
        <v-text-field class="mb-2" type="number" solo outlined dense id="port" v-model="smtpConfig.mail_port"
          hide-details></v-text-field>
        <label class="label-form" for="sender">Mail default sender</label>
        <v-text-field class="mb-2" solo outlined dense v-model="smtpConfig.mail_default_sender" id="sender"
          hide-details></v-text-field>
        <label class="label-form" for="display_name">Mail display name</label>
        <v-text-field class="mb-2" solo outlined dense v-model="smtpConfig.mail_display_name" id="display_name"
          hide-details></v-text-field>
        <label class="label-form" for="mail_use">Mail protocol</label>
        <v-select class="mb-3" :items="protocolOptions" outlined v-model="protocolDecision" solo dense
          hide-details></v-select>
        <v-switch class="mb-2" v-model="auth" color="primary" label="Authentication required" hide-details></v-switch>
        <div v-if="auth">
          <label class="label-form" for="mail_username">Mail username</label>
          <v-text-field class="mb-2" solo outlined dense v-model="smtpConfig.mail_username" id="mail_username"
            hide-details></v-text-field>
          <label class="label-form" for="mail_pass">Mail password</label>
          <v-text-field class="mb-2" solo dense outlined id="mail_pass" v-model="smtpConfig.mail_password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass" hide-details></v-text-field>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="cancel" @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="confirm" outlined @click="testConnection">Test connection</v-btn>
        <v-btn class="white--text" color="confirm" :disabled="saveSMTP" @click="saveChanges">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
  name: "EditSMTPModal",
  props: {
    openModal: Boolean,
    smtpObject: Object,
  },
  data() {
    return {
      smtpConfig: {},
      showPass: false,
      protocolOptions: ["TLS", "SSL", "None"],
      protocolDecision: "",
      setStmpModal: false,
      auth: true,
      saveSMTP: true
    };
  },

  created() {
    if (this.smtpObject.mail_use_ssl) {
      this.protocolDecision = "SSL";
    } else if(this.smtpObject.mail_use_tls) {
      this.protocolDecision = "TLS";
    }else{
      this.protocolDecision = "None"
    }
    this.auth = this.smtpObject.auth;
    this.setStmpModal = this.openModal;
    this.smtpConfig = this.smtpObject;
  },

  methods: {
    saveChanges() {
      if (this.protocolDecision === 'SSL') {
        this.smtpConfig.mail_use_ssl = true;
        this.smtpConfig.mail_use_tls = false;

      } else if (this.protocolDecision === 'TLS') {
        this.smtpConfig.mail_use_ssl = false;
        this.smtpConfig.mail_use_tls = true;
      } else {
        this.smtpConfig.mail_use_ssl = false;
        this.smtpConfig.mail_use_tls = false;
      }
      if (!this.auth) {
        this.smtpConfig.mail_username = "";
        this.smtpConfig.mail_password = "";
      }
      this.smtpConfig.auth = this.auth;
      this.smtpConfig.mail_port = this.smtpConfig.mail_port * 1;
      this.$emit("save-smtp", this.smtpConfig);

    },
    async testConnection() {
      this.$store.commit("setLoader", true);
      try {
        if (this.protocolDecision === 'SSL') {
          this.smtpConfig.mail_use_ssl = true;
          this.smtpConfig.mail_use_tls = false;

        } else if (this.protocolDecision === 'TLS') {
          this.smtpConfig.mail_use_ssl = false;
          this.smtpConfig.mail_use_tls = true;
        } else {
          this.smtpConfig.mail_use_ssl = false;
          this.smtpConfig.mail_use_tls = false;
        }
        if (!this.auth) {
          this.smtpConfig.mail_username = "";
          this.smtpConfig.mail_password = "";
        }
        this.smtpConfig.auth = this.auth;
        this.smtpConfig.mail_port = this.smtpConfig.mail_port * 1;

        const res = await axios.post('/api/admin/v1.0/test_smtp', this.smtpConfig);
        swal("confirmation", res.data.message, "success");
        this.saveSMTP = false;
      } catch (err) {
        const message = err.response.data.error || err.response.data.errors || 'Internal server error';
        swal("Error", message, "error");
      }finally{
        this.$store.commit("setLoader", false);
      }

    },
    close() {
      this.setStmpModal = false;
      this.smtpConfig = {};
      this.$emit("close-smtp", this.setStmpModal);
    },
  },
};
</script>

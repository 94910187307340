<template>
  <v-dialog width="450" persistent v-model="reNewLicense">
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-3">mdi-key-variant</v-icon>
        <span>Renew License</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="renewForm">
        <v-card-text>
          <label class="label-form" for="org-name">Organization Name</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="reNewObj.organization_name"
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form" for="org-name"
            >Purpose for using FDSA</label
          >
          <v-textarea
            cols="60"
            rows="5"
            outlined
            v-model="reNewObj.purpose"
            :rules="[rules.required]"
          ></v-textarea>
          <div>
            <span for="non-commercial"
              >Confirm FDSA is being used for only non-commercial purposes</span
            >
            <v-radio-group v-model="reNewObj.confirm" row>
              <v-radio label="Yes" value="yes"></v-radio>
              <v-radio label="No" value="no"></v-radio>
            </v-radio-group>
            <p v-if="noConfirmation" class="red--text">
              * Please select Yes or Not option in this confirmation field.
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn dark color="confirm" @click="saveChanges">Ok</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ReNewLiceModal",
  props: {
    openModal: Boolean,
    rules:Object
  },
  data() {
    return {
      reNewObj: {},
      reNewLicense: false,
      noConfirmation: false,
    };
  },

  created() {
    this.reNewLicense = this.openModal;
  },

  methods: {
    saveChanges() {
      if (!this.$refs.renewForm.validate()) {
        return;
      }
      if (!this.reNewObj.confirm) {
        this.noConfirmation = true;
        setTimeout(() => {
          this.noConfirmation = false;
        }, 2000);
        return;
      }
      this.$emit('renew-lic', this.reNewObj);
    },
    close(){
        this.reNewLicense=false;
        this.$emit('close-renew', this.reNewLicense);
    }
  },
  watch: {
    reNewLicense: function (value) {
      if (!value) {
        this.$refs.renewForm.reset();
      }
    },
  },
};
</script>

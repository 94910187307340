<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div>
        <v-data-table
          class="elevation-6 mt-10 mb-3"
          :items="registryList"
          :headers="registryTbHeaders"
          :header-props="{ sortIcon: null }"
          :items-per-page.sync="pagination.rowsPerPage"
          :page.sync="pagination.page"
          @update:page="onPageChange"
          @update:items-per-page="onItemsPerPageChange"
          :footer-props="{
            itemsPerPageOptions: [5, 15, 25, 50],
          }"
          :disable-sort="true"
          :server-items-length="totalItems"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.updated_at }}</td>
              <td>
                <v-menu
                  transition="slide-x-transition"
                  bottom
                  right
                  v-if="!item.protected"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="updateRegistry(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list>
                    <v-list-item @click="deleteRegistry(item)">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="delete-icon"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <span v-else>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        class="mr-3"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-lock</v-icon
                      >
                    </template>
                    <span>Protected</span>
                  </v-tooltip>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <v-btn
              class="mt-5"
              text
              color="primary"
              small
              @click="newRegistryPopup = true"
              ><v-icon>mdi-plus</v-icon> Add a new registry</v-btn
            >
          </template>
        </v-data-table>
        <p class="reg-message">- Registry credential must be Read/Write</p>
      </div>
      <AddRegistryModal
        @new-reg="addNewRegistry"
        @close-reg="closeRegistryModal"
        :openModal="newRegistryPopup"
        v-if="newRegistryPopup"
      />
      <UpdateRegistryModal
        @update-reg="confirmedRegUpdate"
        @close-reg="closeEditRegistryModal"
        :reg="editableReg"
        :rules="rules"
        :openModal="editRegModal"
        v-if="editRegModal"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import AddRegistryModal from "@/components/dialogs/AddRegistryModal.vue";
import UpdateRegistryModal from "./dialogs/UpdateRegistryModal.vue";
export default {
  name: "registrySection",
  components: {
    AddRegistryModal,
    UpdateRegistryModal,
  },
  props: {
    admin_token: String,
    rules: Object,
  },
  data() {
    return {
      registryList: [],
      registryTbHeaders: [
        { text: "ID" },
        { text: "Address" },
        { text: "Username" },
        { text: "Created" },
        { text: "Updated" },
        { text: "Actions" },
      ],
      newRegistryPopup: false,
      editRegModal: false,
      editableReg: {},
      pagination: {
        page: 1,
        rowsPerPage: 5,
      },
      totalItems: 0,
    };
  },
  methods: {
    async getRegistries() {
      try {
        const res = await axios.get(`/api/admin/v1.0/registries?page=${this.pagination.page}&page_size=${this.pagination.rowsPerPage}`);
        this.registryList = res.data.data;
        this.totalItems = res.data.total;
      } catch (err) {
        console.log(err);
      }
    },
    updateRegistry(reg) {
      this.editableReg = Object.assign({}, reg);
      this.editRegModal = true;
    },
    async confirmedRegUpdate(reg) {
      this.$store.commit("setLoader", true);
      delete reg.protected;
      try {
        const res = await axios.patch(
          `/api/admin/v1.0/registries/${reg.id}`,
          reg
        );
        console.log(res.status);
        swal("Deleted", "Registry updated!", "success");
        this.getRegistries();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    deleteRegistry(reg) {
      swal({
        title: "Delete",
        text: "Are you sure you want to delete this registry?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.delete(
              `/api/admin/v1.0/registries/${reg.id}`
            );
            console.log(res.status);
            swal("Deleted", "Registry deleted!", "success");
            this.getRegistries();
          } catch (err) {
            console.log(err);
          } finally {
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    async addNewRegistry(newReg) {
      this.newRegistryPopup = false;
      this.$store.commit("setLoader", true);

      //Removing http/https in case user included it in the registry address
      if (newReg.registry_address.includes("http")) {
        newReg.registry_address = newReg.registry_address.replace(
          /^https?:\/\//,
          ""
        );
      }
      if (newReg.registry_address.includes("/")) {
        let registryAddress = newReg.registry_address.split("/");
        newReg.registry_address = registryAddress[0];
      }

      try {
        const res = await axios.post("/api/admin/v1.0/registries", newReg);
        console.log(res.status);
        swal("Confirmation", "Registry added!", "success");
        this.getRegistries();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.getRegistries();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.getRegistries();
    },
    closeRegistryModal(val) {
      this.newRegistryPopup = val;
    },
    closeEditRegistryModal(val) {
      this.editRegModal = val;
    },
  },
  mounted() {
    this.getRegistries();
  },
};
</script>
<style>
.delete-icon {
  color: #850f19;
}
.reg-message {
  font-style: italic;
}
</style>

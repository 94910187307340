<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div class="mt-5">
        <div>
          <v-card color="#f2f3f4">
            <v-card-text>
              <div class="px-4 d-flex justify-space-between">
                <div class="d-flex">
                  <v-text-field
                    outlined
                    dense
                    solo
                    class="filter-inputs"
                    v-model="search"
                    label="User email"
                  ></v-text-field>

                  <v-select
                    outlined
                    dense
                    solo
                    class="filter-inputs mx-3"
                    :items="stateOptions"
                    v-model="params.current_state"
                    label="State"
                    @change="getTasks"
                  >
                  </v-select>

                  <v-select
                    outlined
                    dense
                    solo
                    class="filter-inputs mr-3"
                    :items="viewOptions"
                    item-text="title"
                    item-value="value"
                    v-model="params.view"
                    label="View"
                    @change="getTasks"
                  >
                  </v-select>
                </div>
                <div class="d-flex">
                  <v-btn color="cancel" class="mr-3" @click="params = {}"
                    >Clear</v-btn
                  >
                  <v-btn color="confirm" dark @click="refreshTable">Refresh</v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div>
        <v-data-table
          class="elevation-6 mt-3 mb-3"
          :headers="tableHeaders"
          :items="filteredTasks"
          :search="search"
          :items-per-page.sync="pagination.rowsPerPage"
          :page.sync="pagination.page"
          @update:page="onPageChange"
          @update:items-per-page="onItemsPerPageChange"
          :footer-props="{
            itemsPerPageOptions: [5, 15, 25, 50],
          }"
          :disable-sort="true"
          :server-items-length="totalItems"
        >
          <template slot="item.executors" slot-scope="props">
            <span
              v-for="(item, index) in props.item.executors"
              :key="index"
              class="mb-3"
            >
              <span v-for="(value, key) in item" :key="key">
                <b class="tbl-subsections mt-2">{{ key }}:</b>
                <span v-if="key === 'image'">{{ value }}</span>
                <ul v-if="key === 'command'" class="mb-2">
                  <li v-for="(cmd, index) in value" :key="index">
                    {{ cmd }}
                  </li>
                </ul>
              </span>
            </span>
          </template>
          <template slot="item.inputs" slot-scope="props">
            <ul class="input-list">
              <span v-for="(input, index) in props.item.inputs" :key="index">
                <span v-for="(value, key) in input" :key="key" class="mb-2">
                  <b class="tbl-subsections mt-2">{{ key }}:</b>
                  {{ value }}
                </span>
              </span>
            </ul>
          </template>
          <template slot="item.state" slot-scope="props">
            <v-chip
              small
              dark
              :color="
                props.item.state === 'COMPLETE'
                  ? 'rgb(50 175 16)'
                  : props.item.state === 'QUARANTINED'
                  ? 'rgb(225 201 0)'
                  : props.item.state === 'REJECTED'
                  ? '#785050'
                  : props.item.state === 'SYSTEM_ERROR' ||
                    props.item.state === 'EXECUTOR_ERROR'
                  ? '#da3939'
                  : 'gray'
              "
              >{{ props.item.state }}</v-chip
            >
          </template>
          <template slot="item.actions" slot-scope="props">
            <v-menu transition="slide-x-transition" bottom right v-if="allowed">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item
                  @click="cancelTask(props.item)"
                  v-if="
                    (props.item.state === 'QUEUED' ||
                      props.item.state === 'INITIALIZING' ||
                      props.item.state === 'RUNNING') &&
                    props.item.state !== 'CANCELLED'
                  "
                >
                  <v-list-item-icon>
                    <v-icon>mdi-briefcase-off</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cancel</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="getResults(props.item)"
                  v-if="
                    props.item.state === 'COMPLETE' ||
                    props.item.state === 'QUARANTINED' ||
                    props.item.state === 'EXECUTOR_ERROR'
                  "
                >
                  <v-list-item-icon>
                    <v-icon v-if="props.item.state==='EXECUTOR_ERROR'">mdi-briefcase-remove</v-icon>
                    <v-icon v-else >mdi-briefcase-download</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{props.item.state ==='EXECUTOR_ERROR'? 'Error logs' : 'Review Results'}}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="openReview(props.item)"
                  v-if="props.item.state === 'QUARANTINED'"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-briefcase-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Approve/Reject</v-list-item-title>
                </v-list-item>
                <v-list-item @click="showHistory(props.item)">
                  <v-list-item-icon>
                    <v-icon>mdi-briefcase-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>History</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <!-- Task history modal -->
    <TaskHistoryModal
      @close-history="closeHistoryModal"
      :taskHistory="historyList"
      :showHistory="taskHistoryPopup"
      v-if="taskHistoryPopup"
    />

    <!-- Review task modal -->
    <ReviewTaskModal
      @close-review="closeReviewModal"
      @review="reviewTask"
      :openTaskReviewModal="reviewTaskPopup"
      :rules="rules"
      v-if="reviewTaskPopup"
    />
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import TaskHistoryModal from "@/components/dialogs/TaskHistoryModal.vue";
import ReviewTaskModal from "@/components/dialogs/ReviewTaskModal.vue";
export default {
  name: "tasksUsers",
  components: {
    TaskHistoryModal,
    ReviewTaskModal,
  },
  props: {
    admin_token: String,
    rules: Object,
    allowed: Boolean,
  },
  data() {
    return {
      search: "",
      tableHeaders: [],
      stateOptions: [
        "RUNNING",
        "INITIALIZING",
        "QUEUED",
        "QUARANTINED",
        "COMPLETE",
        "REJECTED",
        "SYSTEM_ERROR",
        "EXECUTOR_ERROR",
      ],
      viewOptions: [
        { title: "STANDARD", value: "MINIMAL" },
        { title: "DETAILED", value: "FULL" },
      ],
      params: {},
      tasks: [],
      minimal: true,
      taskHistoryPopup: false,
      historyList: [],
      targetTask: {},
      reviewTaskPopup: false,
      reviewIdTask: "",
      interval: null,
      roleUrl: "/api/admin/v1.0/",
      pagination: {
        page: 1,
        rowsPerPage: 5,
      },
      totalItems: 0,
    };
  },
  methods: {
    refreshTable() {
      if(!this.interval){
        this.interval = setInterval(this.getTasks, 3000); // Call every 5 seconds
      }
    },
    async getTasks() {
      const config = {
        headers: {
          Authorization: `Bearer: ${this.$store.state.token}`,
        },
        params: this.params,
      };
      if (!this.allowed) this.roleUrl = "/api/common/v1.1.0/";
      try {
        const res = await axios.get(`${this.roleUrl}tasks?page=${this.pagination.page}&page_size=${this.pagination.rowsPerPage}`, config);
        this.totalItems = res.data.total;
        let data = res.data.data.sort((a, b) =>{
          return new Date(b.created_at) - new Date(a.created_at);
        });
        let formattedData = [];

        if (data.length > 3) {
          formattedData = data.map((el) => {
            return {
              ...el,
              created_at: moment(el.created_at).format(
                "D MMMM YYYY - HH:mm:ss"
              ),
            };
          });
          this.tasks = formattedData;
        } else {
          this.tasks = data;
        }

        if (this.tasks.length > 0) {
          const columns = Object.keys(this.tasks[0]);
          let headers = [];
          if (this.params.view === "MINIMAL") {
            const basicValues = [
              "created_at",
              "id",
              "name",
              "state",
              "user_email",
            ];
            columns.map((el) => {
              if (basicValues.includes(el)) {
                headers.push({
                  text: el.split("_").join(" "),
                  value: el,
                });
              }
            });
          } else {
            columns.map((el) => {
              if (el !== "current_state") {
                headers.push({
                  text: el.split("_").join(" "),
                  value: el,
                });
              }
            });
          }
          headers.push({
            text: "Actions",
            value: "actions",
            sortable: false,
          });
          this.tableHeaders = headers;
        }
        //check if all tasks have state COMPLETE or REJECTED or SYSTEM_ERROR or EXECUTOR_ERROR or QUARANTINED and stop the interval
        const allTasksFinished = this.tasks.every(
          (el) =>
            el.state === "COMPLETE" ||
            el.state === "REJECTED" ||
            el.state === "SYSTEM_ERROR" ||
            el.state === "EXECUTOR_ERROR" ||
            el.state === "QUARANTINED"
        );
        if (allTasksFinished) {
          clearInterval(this.interval);
          this.interval = null;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async showHistory(task) {
      try {
        const res = await axios.get(`/api/admin/v1.0/tasks/${task.id}/history`);
        this.historyList = res.data.history;
        this.taskHistoryPopup = true;
      } catch (err) {
        console.log(err);
      }
    },
    async cancelTask(task) {
      const payload = {
        state: "CANCELLED",
        details: "",
      };
      swal({
        title: "Cancel",
        text: "Are you sure you want to cancel this task?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.patch(
              `/api/admin/v1.0/tasks/${task.id}/update`,
              payload
            );
            console.log(res.status);
            swal("Confirmation", "Task cancelled", "success");
            this.getTasks();
          } catch (err) {
            console.log(err);
          } finally {
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    openReview(task) {
      this.reviewIdTask = task.id;
      this.reviewTaskPopup = true;
    },
    async reviewTask(taskReviewed) {
      this.$store.commit("setLoader", true);
      taskReviewed.reviewer = this.$store.state.uuid;
      if (!taskReviewed.reason) taskReviewed.reason = "";
      this.reviewTaskPopup = false;
      try {
        const res = await axios.post(
          `/api/admin/v1.0/tasks/${this.reviewIdTask}/review`,
          taskReviewed
        );
        console.log(res.status);
        this.getTasks();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async getResults(task) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.get(
          `/api/admin/v1.0/tasks/${task.id}/results`,
          {
            responseType: "blob",
          }
        );

        if (task.state === "EXECUTOR_ERROR") {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "text/plain" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "task-error.log");
          document.body.appendChild(link);
          link.click();
        } else {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/zip" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "task-results.zip");
          document.body.appendChild(link);
          link.click();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.getTasks();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.getTasks();
    },
    closeHistoryModal(val) {
      this.taskHistoryPopup = val;
    },
    closeReviewModal(val) {
      this.reviewTaskPopup = val;
    },
  },
  mounted() {
    this.params.view = "MINIMAL";
    this.getTasks();
    this.interval = setInterval(this.getTasks, 3000); // Call every 3 seconds
  },
  computed:{
    filteredTasks() {
      return this.tasks.filter((task) => {
        return (
          task.user_email.toLowerCase().includes(this.search?.toLowerCase())
        );
      });
    },
  }
};
</script>
<style scoped>
.tbl-subsections {
  display: block;
  text-transform: capitalize;
}

.filter-inputs {
  min-width: 100px;
  max-width: 80%;
}

.history-container {
  max-height: 500px;
  overflow-y: scroll;
}

.history-headers {
  width: 33%;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c("v-card-text", { staticClass: "px-15" }, [
        _c("div", { staticClass: "list-container" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-space-between flex-wrap" },
            [
              _c("WhiteListBoardVue", {
                attrs: {
                  description: _vm.adminDesc,
                  category: "admin",
                  title: "Admin Whitelist",
                },
              }),
              _c("WhiteListBoardVue", {
                staticClass: "auth",
                attrs: {
                  description: _vm.authDesc,
                  category: "auth",
                  title: "Keycloak Whitelist",
                },
              }),
              _c("WhiteListBoardVue", {
                staticClass: "admin",
                attrs: {
                  description: _vm.apiDesc,
                  category: "api",
                  title: "DARs API Whitelist",
                },
              }),
              _c("WhiteListBoardVue", {
                attrs: {
                  description: _vm.commonDesc,
                  category: "common",
                  title: "Common API Whitelist",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "smtp-token" },
            [
              _c("SMTPConfig", { attrs: { rules: _vm.rules } }),
              _c(
                "div",
                [
                  _c("WebHookSectionVue", { attrs: { rules: _vm.rules } }),
                  _c("TokenLifetimeSection"),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog v-model="rolesModal" width="400" persistent>
    <v-card>
      <v-card-title>User roles</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list rounded>
          <p class="text-center instructions">
            Please select or deselect the role you want for this user
          </p>
          <v-list-item-group v-model="roleSelected" color="primary">
            <v-list-item
              v-for="(item, index) in roleList"
              :key="index"
              class="roles"
            >
              <v-list-item-icon>
                <v-icon v-if="item === 'administrator'">mdi-account-cog</v-icon>
                <v-icon v-else-if="item === 'standard'">mdi-account</v-icon>
                <v-icon v-else>mdi-account-arrow-left</v-icon>
              </v-list-item-icon>
              <label class="ml-3 label-form">{{ item }}</label>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Close</v-btn>
        <v-btn dark color="confirm" @click="updateRole">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "UserRolesForm",
  props: {
    showRoles: Boolean,
    userRolesList: Array,
    actualRole: Number,
  },
  data() {
    return {
      rolesModal: false,
      roleList: [],
      roleSelected: null,
    };
  },

  created() {
    this.roleList = this.userRolesList;
    this.roleSelected = this.actualRole;
    this.rolesModal = this.showRoles;
  },

  methods: {
    updateRole() {
      this.$emit("update-role", this.roleSelected);
    },
    close() {
      this.rolesModal = false;
      this.$emit("close-roles", this.rolesModal);
    },
  },
};
</script>
<style scoped>
.roles {
  border: 0.5px #e3e4e6 solid;
}
</style>

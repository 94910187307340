<template>
  <v-dialog v-model="newWhModal" width="400" persistent>
    <v-card>
      <v-card-title> Add Web Hook </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newWebHookForm">
        <v-card-text class="mt-2">
          <label class="label-form" for="url">URL</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="webHook.url"
            class="mt-1"
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="saveChanges">Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddWebHook",
  props: {
    openModal: Boolean,
    rules: Object,
  },
  data() {
    return {
      webHook: {},
      newWhModal: false,
    };
  },
  methods: {
    close() {
      this.newWhModal = false;
      this.$emit("closeWH", this.newWhModal);
    },
    saveChanges() {
      if (!this.$refs.newWebHookForm.validate()) {
        return;
      }
      this.$emit("addWH", this.webHook);
    },
  },
  watch: {
    newWhModal: function (val) {
      if (!val) {
        this.$refs.newWebHookForm.reset();
      }
    },
  },
  created() {
    this.newWhModal = this.openModal;
  },
};
</script>

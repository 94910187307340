<template>
  <v-dialog v-model="addNewRegistryModal" width="450" persistent>
    <v-card color="#efefef">
      <v-card-title>
        Add new registry
        <v-icon color="primary" class="ml-3">mdi-dns</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="regForm">
        <v-card-text>
          <label class="label-form" for="regAdress">Registry Address</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newRegistry.registry_address"
            id="regAdress"
            prefix="https://"
          ></v-text-field>
          <label class="label-form" for="reguser">Registry Username</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newRegistry.registry_username"
            id="reguser"
            autocomplete="new-username"
          ></v-text-field>
          <label class="label-form" for="regpass">Registry Password</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newRegistry.registry_password"
            id="regpass"
            type="password"
            autocomplete="new-password"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="saveChanges">Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddRegistryModal",
  props: {
    openModal: Boolean,
  },
  data() {
    return {
      newRegistry: {},
      addNewRegistryModal: false,
    };
  },

  created() {
    this.addNewRegistryModal = this.openModal;
  },

  methods: {
    saveChanges() {
      this.$emit("new-reg", this.newRegistry);
    },
    close() {
      this.addNewRegistryModal = false;
      this.$emit("close-reg", this.addNewRegistryModal);
    },
  },
  watch: {
    addNewRegistryModal: function (value) {
      if (!value) {
        this.$refs.regForm.reset();
      }
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-10" },
        [
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "elevation-6 mt-5 mb-10",
                attrs: {
                  items: _vm.filteredDars,
                  headers: _vm.darHeaders,
                  search: _vm.search,
                  "items-per-page": _vm.pagination.rowsPerPage,
                  "server-items-length": _vm.totalItems,
                  page: _vm.pagination.page,
                  "footer-props": {
                    itemsPerPageOptions: [5, 15, 25, 50],
                  },
                  "disable-sort": true,
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                  },
                  "update:items-per-page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                    },
                    _vm.onItemsPerPageChange,
                  ],
                  "update:page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "page", $event)
                    },
                    _vm.onPageChange,
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "dar-actions search-box" }, [
                          _c(
                            "div",
                            { staticClass: "py-3 search-input" },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-4",
                                attrs: {
                                  label: "Search",
                                  dense: "",
                                  solo: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "my-3 mx-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-refresh",
                                  attrs: { color: "primary" },
                                  on: { click: _vm.getDars },
                                },
                                [_vm._v("Refresh")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("v-divider"),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.project_code))]),
                          _c("td", [_vm._v(_vm._s(item.requester.email))]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.created_at)
                                    .format("D MMMM YYYY - HH:mm:ss")
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .moment(item.end_date)
                                    .format("D MMMM YYYY - HH:mm:ss")
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            item.trusted_dataset
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          size: "20",
                                          color: "green",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [_vm._v("D")]
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "chip-text" }, [
                                      _vm._v("Data set"),
                                    ]),
                                  ],
                                  1
                                )
                              : item.trusted_dar
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          size: "20",
                                          color: "green",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [_vm._v("P")]
                                        ),
                                      ]
                                    ),
                                    _c("span", { staticClass: "chip-text" }, [
                                      _vm._v("Project ID"),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "d-flex" },
                                  [
                                    _c("v-avatar", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        size: "20",
                                        color: "#706b6b",
                                        small: "",
                                      },
                                    }),
                                    _c("span", { staticClass: "chip-text" }, [
                                      _vm._v("Approval required"),
                                    ]),
                                  ],
                                  1
                                ),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  item.web_hook_synched
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "green" } },
                                            [_vm._v("mdi-check-circle")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.web_hook_synched === false
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color: "gray",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "mdi-close-circle"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Not synched or web hook not set"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("td", [_vm._v(_vm._s(item.status))]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "details-btn",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDetails(item)
                                    },
                                  },
                                },
                                [_vm._v("Show")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              item.status === "RECEIVED" &&
                              _vm.allowed &&
                              !item.web_hook_synched
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        transition: "slide-x-transition",
                                        bottom: "",
                                        right: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("mdi-dots-vertical")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        [
                                          !item.web_hook_synched
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openReview(
                                                        "APPROVED",
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-check-bold"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Approve"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !item.web_hook_synched
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openReview(
                                                        "DENIED",
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-close-thick"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Reject"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.webHookSet &&
                                          !item.web_hook_synched
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resend(
                                                        item.request_uuid
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-send"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v("Resend"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : item.status === "APPROVED" ||
                                  item.status === "DENIED"
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(" Reviewed "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "confirm",
                                          },
                                        },
                                        [_vm._v("mdi-check-all")]
                                      ),
                                    ],
                                    1
                                  )
                                : item.web_hook_synched
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(" Synched "),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "confirm",
                                          },
                                        },
                                        [_vm._v("mdi-check-all")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.detailsPopup
            ? _c("DarDetailsModal", {
                attrs: { darInfo: _vm.details, showDetails: _vm.detailsPopup },
                on: { "close-details": _vm.closeDetails },
              })
            : _vm._e(),
          _vm.reviewPopup
            ? _c("DarReviewModal", {
                attrs: {
                  trustedDts: _vm.trustedDar,
                  level: _vm.darLevel,
                  decision: _vm.decision,
                  rules: _vm.rules,
                  reviewModal: _vm.reviewPopup,
                },
                on: { "submit-review": _vm.reviewDar, close: _vm.closeReview },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog v-model="reviewDarModal" width="500" persistent>
    <v-card color="#efefef">
      <v-card-title
        >Review DAR
        <v-icon color="primary" class="ml-3"
          >mdi-file-search</v-icon
        ></v-card-title
      >
      <v-divider></v-divider>
      <v-form lazy-validation ref="reviewDarForm">
        <v-card-text>
          <label class="label-form" for="reason">Reason</label>
          <v-textarea
            outlined
            cols="60"
            rows="5"
            v-model="reason"
            :rules="[rules.required]"
          ></v-textarea>
          <v-checkbox
            class="mt-0"
            v-model="trusted"
            label="This Project ID is a trusted Project, do not quarantine tasks"
            v-if="level === 2 && decision === 'APPROVED' && !trustedDts"
          >
          </v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn dark color="confirm" @click="sendReview">Send</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import swal from "sweetalert";
export default {
  name: "DarReviewModal",
  props: {
    rules: Object,
    reviewModal: Boolean,
    level: Number,
    decision: String,
    trustedDts: Boolean,
  },
  data() {
    return {
      reviewDarModal: false,
      reason: "",
      trusted: false,
    };
  },

  created() {
    this.reviewDarModal = this.reviewModal;
  },

  methods: {
    sendReview() {
      if (!this.$refs.reviewDarForm.validate()) {
        return;
      }
      if (this.trusted) {
        swal({
          title: "Warning",
          text: " Please verify that you intend to allow non-quarantined tasks on this Project ID for this dataset. With this setting, it's technically possible that researcher models may inadvertently leak record-level data, which will not be reviewed. Please click OK to allow this setting",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.$emit("submit-review", {
              reason: this.reason,
              trusted: this.trusted,
            });
          }
        });
      } else {
        this.$emit("submit-review", {
          reason: this.reason,
          trusted: this.trusted,
        });
      }
    },
    close() {
      this.reviewDarModal = false;
      this.$emit("close", this.reviewDarModal);
    },
  },
  watch: {
    reviewDarModal: function (value) {
      if (!value) {
        this.$refs.reviewDarForm.reset();
      }
    },
  },
};
</script>
<style scoped>
.v-label {
  font-size: 15px;
}
</style>

<template>
  <v-dialog v-model="newCatalogueModal" width="800" persistent>
    <v-card color="#efefef">
      <v-card-title class="d-flex justify-space-between">
        <div>
          <v-icon class="mr-3" color="primary">mdi-book-open-variant</v-icon>
          Add Catalogue
        </div>
        <span v-if="trustedDataset" class="trust-title">Trusted Dataset <v-icon class="ml-3" color="green" small>mdi-lock-open-variant</v-icon></span>
        <span v-else class="trust-title">Untrusted Dataset <v-icon class="ml-3" color="red" small>mdi-lock</v-icon></span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="catalogueForm">
        <v-card-text>
          <v-container>
            <v-row class="justify-center">
              <v-col lg="5" md="5" sm="12" xs="12">
                <v-row>
                  <label class="label-form" for="title">Title</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="title"
                    id="title"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col lg="5" md="5" sm="12" xs="12" offset-md="1">
                <v-row>
                  <label class="label-form" for="version">Version</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="version"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col lg="5" md="5" sm="12" xs="12">
                <v-row>
                  <label class="label-form" for="description"
                    >Description</label
                  >
                </v-row>
                <v-row>
                  <v-textarea
                    solo
                    outlined
                    dense
                    v-model="description"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-row>
                <v-row>
                  <label class="label-form">Publication date</label>
                </v-row>
                <v-row>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="publicationDate"
                        dense
                        solo
                        outlined
                        placeholder="Select date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="publicationDate"
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-row>
              </v-col>
              <v-col lg="5" md="5" sm="12" xs="12" offset-md="1">
                <v-row>
                  <label class="label-form" for="author">Author</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    :rules="[rules.required]"
                    v-model="creator"
                    id="author"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <label class="label-form" for="contact-email"
                    >Contact Email</label
                  >
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="contactPoint.email"
                    id="contact-email"
                    :rules="[rules.email, rules.required]"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <label class="label-form" for="contact-name"
                    >Contact Name</label
                  >
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="contactPoint.name"
                    id="contact-name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col lg="5" md="5" sm="12" xs="12">
                <v-row>
                  <label class="label-form" for="license">License</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="license"
                    id="license"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <label class="label-form" for="access"
                    >Rights (Dataset terms of use)</label
                  >
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="accessRights"
                    :rules="[rules.required]"
                    id="access"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col lg="5" md="5" sm="12" xs="12" offset-md="1">
                <v-row>
                  <label class="label-form">Publisher</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="publisher.name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <label class="label-form">Publisher URL</label>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="publisher.url"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col lg="5" md="5" sm="12" xs="12">
                <v-row>
                  <h5 class="ml-3 mb-3">Keywords</h5>
                </v-row>
                <v-row>
                  <v-text-field
                    solo
                    dense
                    outlined
                    v-model="newKeyword"
                    placeholder="Add keywords separated by commas"
                    hide-details
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-btn class="mt-2" color="primary" @click="addKeyWord"
                    >Add</v-btn
                  >
                </v-row>
              </v-col>
              <v-col lg="5" md="5" sm="12" xs="12" offset-md="1">
                <div class="ml-3">
                  <v-chip
                    v-for="(keyword, index) in keywords"
                    :key="index"
                    close
                    color="blue"
                    dark
                    class="mb-2 mr-2"
                    @click:close="deleteKeyword(index)"
                    >{{ keyword }}</v-chip
                  >
                  <p class="text-center kw-text" v-if="keywords.length === 0">
                    No keywords added
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Cancel</v-btn>
        <v-btn color="confirm" dark @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddCatalogueModal",
  props: {
    openModal: Boolean,
    trustedDataset: Boolean,
    rules: Object,
  },

  data() {
    return {
      title: "",
      version: "",
      creator: "",
      description: "",
      keywords: [],
      contactPoint: {},
      publisher: {},
      accessRights: "",
      license: "",
      newKeyword: "",
      newCatalogueModal: false,
      menu: false,
      publicationDate: null,
    };
  },

  mounted() {
    this.newCatalogueModal = this.openModal;
  },

  methods: {
    addKeyWord() {
      if (this.newKeyword === "") return;
      let list = this.newKeyword.split(",");
      this.keywords = [...this.keywords, ...list];
      this.newKeyword = "";
    },
    deleteKeyword(index) {
      this.keywords.splice(index, 1);
    },
    close() {
      this.newCatalogueModal = false;
      this.$emit("close-add-catalogue", this.newCatalogueModal);
    },
    save() {
      if (!this.$refs.catalogueForm.validate()) {
        return;
      }
      const payload = {
        title: this.title,
        version: this.version,
        description: this.description,
        keywords: this.keywords,
        creator: this.creator,
        contactPoint: this.contactPoint,
        publisher: this.publisher,
        accessRights: this.accessRights,
        license: this.license,
        publication_date: this.publicationDate,
      };
      this.$emit("saveCatalogue", payload);
    },
  },
};
</script>
<style scoped>
.kw-text {
  margin-top: 26px;
  font-style: italic;
  color: slategray;
}
.trust-title{
  font-size: 15px;
  color: rgb(97, 97, 97);
}
</style>

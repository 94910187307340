var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: "" },
      model: {
        value: _vm.editWHModal,
        callback: function ($$v) {
          _vm.editWHModal = $$v
        },
        expression: "editWHModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Edit Web Hook ")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "mt-2" },
            [
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "url" } },
                [_vm._v("URL")]
              ),
              _c("v-text-field", {
                staticClass: "mt-1",
                attrs: { solo: "", outlined: "", dense: "" },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", dark: "" },
                  on: { click: _vm.saveChanges },
                },
                [_vm._v("Update")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
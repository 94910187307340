var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "loading-img" },
      [
        _c("v-img", {
          staticClass: "ma-auto",
          attrs: {
            width: "300",
            height: "300",
            src: require("@/assets/Logo-loading2.gif"),
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("v-img", {
          staticClass: "ma-auto",
          attrs: {
            width: "350",
            height: "200",
            src: require("@/assets/loading.gif"),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
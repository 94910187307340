var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.reviewDarModal,
        callback: function ($$v) {
          _vm.reviewDarModal = $$v
        },
        expression: "reviewDarModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v("Review DAR "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-file-search")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "reviewDarForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "reason" } },
                    [_vm._v("Reason")]
                  ),
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      cols: "60",
                      rows: "5",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.reason,
                      callback: function ($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason",
                    },
                  }),
                  _vm.level === 2 &&
                  _vm.decision === "APPROVED" &&
                  !_vm.trustedDts
                    ? _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: {
                          label:
                            "This Project ID is a trusted Project, do not quarantine tasks",
                        },
                        model: {
                          value: _vm.trusted,
                          callback: function ($$v) {
                            _vm.trusted = $$v
                          },
                          expression: "trusted",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "confirm" },
                      on: { click: _vm.sendReview },
                    },
                    [_vm._v("Send")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
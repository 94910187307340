var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", persistent: "" },
      model: {
        value: _vm.setStmpModal,
        callback: function ($$v) {
          _vm.setStmpModal = $$v
        },
        expression: "setStmpModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "primary" } },
                [_vm._v("mdi-email")]
              ),
              _vm._v(" SMTP Configuration "),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "server" } },
                [_vm._v("Mail server")]
              ),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  solo: "",
                  outlined: "",
                  dense: "",
                  id: "server",
                  "hide-details": "",
                },
                model: {
                  value: _vm.smtpConfig.mail_server,
                  callback: function ($$v) {
                    _vm.$set(_vm.smtpConfig, "mail_server", $$v)
                  },
                  expression: "smtpConfig.mail_server",
                },
              }),
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "port" } },
                [_vm._v("Mail Port")]
              ),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  type: "number",
                  solo: "",
                  outlined: "",
                  dense: "",
                  id: "port",
                  "hide-details": "",
                },
                model: {
                  value: _vm.smtpConfig.mail_port,
                  callback: function ($$v) {
                    _vm.$set(_vm.smtpConfig, "mail_port", $$v)
                  },
                  expression: "smtpConfig.mail_port",
                },
              }),
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "sender" } },
                [_vm._v("Mail default sender")]
              ),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  solo: "",
                  outlined: "",
                  dense: "",
                  id: "sender",
                  "hide-details": "",
                },
                model: {
                  value: _vm.smtpConfig.mail_default_sender,
                  callback: function ($$v) {
                    _vm.$set(_vm.smtpConfig, "mail_default_sender", $$v)
                  },
                  expression: "smtpConfig.mail_default_sender",
                },
              }),
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "display_name" } },
                [_vm._v("Mail display name")]
              ),
              _c("v-text-field", {
                staticClass: "mb-2",
                attrs: {
                  solo: "",
                  outlined: "",
                  dense: "",
                  id: "display_name",
                  "hide-details": "",
                },
                model: {
                  value: _vm.smtpConfig.mail_display_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.smtpConfig, "mail_display_name", $$v)
                  },
                  expression: "smtpConfig.mail_display_name",
                },
              }),
              _c(
                "label",
                { staticClass: "label-form", attrs: { for: "mail_use" } },
                [_vm._v("Mail protocol")]
              ),
              _c("v-select", {
                staticClass: "mb-3",
                attrs: {
                  items: _vm.protocolOptions,
                  outlined: "",
                  solo: "",
                  dense: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.protocolDecision,
                  callback: function ($$v) {
                    _vm.protocolDecision = $$v
                  },
                  expression: "protocolDecision",
                },
              }),
              _c("v-switch", {
                staticClass: "mb-2",
                attrs: {
                  color: "primary",
                  label: "Authentication required",
                  "hide-details": "",
                },
                model: {
                  value: _vm.auth,
                  callback: function ($$v) {
                    _vm.auth = $$v
                  },
                  expression: "auth",
                },
              }),
              _vm.auth
                ? _c(
                    "div",
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label-form",
                          attrs: { for: "mail_username" },
                        },
                        [_vm._v("Mail username")]
                      ),
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        attrs: {
                          solo: "",
                          outlined: "",
                          dense: "",
                          id: "mail_username",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.smtpConfig.mail_username,
                          callback: function ($$v) {
                            _vm.$set(_vm.smtpConfig, "mail_username", $$v)
                          },
                          expression: "smtpConfig.mail_username",
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "label-form",
                          attrs: { for: "mail_pass" },
                        },
                        [_vm._v("Mail password")]
                      ),
                      _c("v-text-field", {
                        staticClass: "mb-2",
                        attrs: {
                          solo: "",
                          dense: "",
                          outlined: "",
                          id: "mail_pass",
                          "append-icon": _vm.showPass
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          type: _vm.showPass ? "text" : "password",
                          "hide-details": "",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.showPass = !_vm.showPass
                          },
                        },
                        model: {
                          value: _vm.smtpConfig.mail_password,
                          callback: function ($$v) {
                            _vm.$set(_vm.smtpConfig, "mail_password", $$v)
                          },
                          expression: "smtpConfig.mail_password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", outlined: "" },
                  on: { click: _vm.testConnection },
                },
                [_vm._v("Test connection")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "confirm", disabled: _vm.saveSMTP },
                  on: { click: _vm.saveChanges },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "450", persistent: "" },
      model: {
        value: _vm.reNewLicense,
        callback: function ($$v) {
          _vm.reNewLicense = $$v
        },
        expression: "reNewLicense",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "primary" } },
                [_vm._v("mdi-key-variant")]
              ),
              _c("span", [_vm._v("Renew License")]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "renewForm" },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "org-name" } },
                    [_vm._v("Organization Name")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.reNewObj.organization_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.reNewObj, "organization_name", $$v)
                      },
                      expression: "reNewObj.organization_name",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "org-name" } },
                    [_vm._v("Purpose for using FDSA")]
                  ),
                  _c("v-textarea", {
                    attrs: {
                      cols: "60",
                      rows: "5",
                      outlined: "",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.reNewObj.purpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.reNewObj, "purpose", $$v)
                      },
                      expression: "reNewObj.purpose",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c("span", { attrs: { for: "non-commercial" } }, [
                        _vm._v(
                          "Confirm FDSA is being used for only non-commercial purposes"
                        ),
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "" },
                          model: {
                            value: _vm.reNewObj.confirm,
                            callback: function ($$v) {
                              _vm.$set(_vm.reNewObj, "confirm", $$v)
                            },
                            expression: "reNewObj.confirm",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Yes", value: "yes" },
                          }),
                          _c("v-radio", {
                            attrs: { label: "No", value: "no" },
                          }),
                        ],
                        1
                      ),
                      _vm.noConfirmation
                        ? _c("p", { staticClass: "red--text" }, [
                            _vm._v(
                              " * Please select Yes or Not option in this confirmation field. "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "confirm" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Ok")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
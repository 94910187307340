var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex justify-center mt-2" },
    [
      _c(
        "v-card",
        { staticClass: "token-card", attrs: { width: "100%", height: "225" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", "max-width": "250" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-icon",
                            _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                            [_vm._v("mdi-information")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.description))])]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  solo: "",
                  dense: "",
                  outlined: "",
                  disabled: _vm.editable,
                  type: "number",
                },
                model: {
                  value: _vm.lifetime,
                  callback: function ($$v) {
                    _vm.lifetime = $$v
                  },
                  expression: "lifetime",
                },
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.editable
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.editable = false
                        },
                      },
                    },
                    [_vm._v("Edit")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.editTokenLifeTime },
                    },
                    [_vm._v("Save")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <LoadingScreen v-if="$store.state.keycloak===null" />
    <mainSection v-else/>
  </div>
</template>

<script>
import mainSection from "../components/MainSection.vue";
import LoadingScreen from "../components/LoadingScreen.vue";
export default {
  name: "HomeView",
  components: {
    mainSection,
    LoadingScreen
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "540" },
      model: {
        value: _vm.updateSrvModal,
        callback: function ($$v) {
          _vm.updateSrvModal = $$v
        },
        expression: "updateSrvModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Edit service "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-pencil")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "editServiceForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "label-form" }, [_vm._v("Name")]),
                  _c("v-text-field", {
                    attrs: { solo: "", outlined: "", dense: "" },
                    model: {
                      value: _vm.serviceToEdit.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceToEdit, "name", $$v)
                      },
                      expression: "serviceToEdit.name",
                    },
                  }),
                  _c(
                    "v-row",
                    _vm._l(
                      _vm.serviceToEdit.organization,
                      function (value, name, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c(
                              "v-col",
                              [
                                _c("label", { staticClass: "label-form" }, [
                                  _vm._v("Organization " + _vm._s(name)),
                                ]),
                                _c("v-text-field", {
                                  attrs: { solo: "", outlined: "", dense: "" },
                                  model: {
                                    value: _vm.serviceToEdit.organization[name],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.serviceToEdit.organization,
                                        name,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "serviceToEdit.organization[name]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Contact URL"),
                  ]),
                  _c("v-text-field", {
                    attrs: { solo: "", outlined: "", dense: "" },
                    model: {
                      value: _vm.serviceToEdit.contactUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceToEdit, "contactUrl", $$v)
                      },
                      expression: "serviceToEdit.contactUrl",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Documentation URL"),
                  ]),
                  _c("v-text-field", {
                    attrs: { solo: "", outlined: "", dense: "" },
                    model: {
                      value: _vm.serviceToEdit.documentationUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceToEdit, "documentationUrl", $$v)
                      },
                      expression: "serviceToEdit.documentationUrl",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Description"),
                  ]),
                  _c("v-textarea", {
                    attrs: { solo: "", outlined: "", rows: "3" },
                    model: {
                      value: _vm.serviceToEdit.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.serviceToEdit, "description", $$v)
                      },
                      expression: "serviceToEdit.description",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "edit" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Edit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
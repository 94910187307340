<template>
  <v-dialog v-model="addNewIp" width="350" persistent>
    <v-card>
      <v-card-title>New IP</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          solo
          outlined
          dense
          hide-details
          v-model="newIP"
          placeholder="x.x.x.x or x.x.x.x/x"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Cancel</v-btn>
        <v-btn color="confirm" dark @click="saveChanges">Add</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'AddIPModal',
    props:{
        openModal:Boolean,
    },
    data() {
        return {
            newIP: "",
            addNewIp:false,
        };
    },

    created() {
        this.addNewIp = this.openModal;
    },

    methods: {
        saveChanges(){
            this.$emit('new-ip', this.newIP);
            this.newIP="";
        },
        close(){
            this.addNewIp = false;
            this.$emit('close-ip', this.addNewIp);
        }
    },
};
</script>
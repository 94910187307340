var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.noLicense
        ? _c(
            "v-alert",
            {
              staticClass: "ma-0",
              attrs: {
                color: _vm.color,
                dark: "",
                icon: _vm.icon,
                dense: "",
                dismissible: "",
              },
            },
            [
              _c("span", { staticClass: "alert-message" }, [
                _vm._v(" " + _vm._s(_vm.text) + " "),
              ]),
              _c(
                "span",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3 red--text",
                      attrs: {
                        "x-small": "",
                        rounded: "",
                        dense: "",
                        color: "#fff",
                      },
                      on: { click: _vm.openModals },
                    },
                    [_vm._v(_vm._s(_vm.btnText))]
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-3",
                                        attrs: { dark: "" },
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-information ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2719979611
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.tltmsg))])]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.newKeyModal
        ? _c("AddNewKeyModal", {
            attrs: { openModal: _vm.newKeyModal },
            on: { "new-key": _vm.addLicense, "close-key": _vm.closeAddKey },
          })
        : _vm._e(),
      _vm.reNewLicModal
        ? _c("ReNewLiceModal", {
            attrs: { rules: _vm.rules, openModal: _vm.reNewLicModal },
            on: {
              "renew-lic": _vm.renewLicence,
              "close-renew": _vm.closeRenewLicense,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-footer", { attrs: { absolute: "", dark: "" } }, [
    _c("p", [
      _vm._v(
        " © Federated Data Sharing Appliance. Version: " +
          _vm._s(_vm.version) +
          " - Release date: " +
          _vm._s(_vm.releaseDate) +
          " "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div>
        <v-data-table
          class="elevation-6 mt-5 mb-10"
          :items="filteredDars"
          :headers="darHeaders"
          :search="search"
          :items-per-page.sync="pagination.rowsPerPage"
          :server-items-length="totalItems"
          :page.sync="pagination.page"
          @update:page="onPageChange"
          @update:items-per-page="onItemsPerPageChange"
          :footer-props="{
            itemsPerPageOptions: [5, 15, 25, 50],
          }"
          :disable-sort="true"
        >
          <template v-slot:top>
            <div class="dar-actions search-box">
              <div class="py-3 search-input">
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                  dense
                  solo
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </div>
              <div class="my-3 mx-2">
                <v-btn class="btn-refresh" color="primary" @click="getDars"
                  >Refresh</v-btn
                >
              </div>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.project_code }}</td>
              <td>{{ item.requester.email }}</td>
              <td>
                {{ moment(item.created_at).format("D MMMM YYYY - HH:mm:ss") }}
              </td>
              <td>
                {{ moment(item.end_date).format("D MMMM YYYY - HH:mm:ss") }}
              </td>
              <td>
                <div class="d-flex" v-if="item.trusted_dataset">
                  <v-avatar class="mr-2" size="20" color="green" small dark
                    ><span class="white--text">D</span></v-avatar
                  >
                  <span class="chip-text">Data set</span>
                </div>
                <div class="d-flex" v-else-if="item.trusted_dar">
                  <v-avatar class="mr-2" size="20" color="green" small dark>
                    <span class="white--text">P</span></v-avatar
                  >
                  <span class="chip-text">Project ID</span>
                </div>
                <div class="d-flex" v-else>
                  <v-avatar
                    class="mr-2"
                    size="20"
                    color="#706b6b"
                    small
                  ></v-avatar>
                  <span class="chip-text">Approval required</span>
                </div>
              </td>
              <td>
                <v-layout justify-center>
                  <span v-if="item.web_hook_synched">
                    <v-icon color="green">mdi-check-circle</v-icon>
                  </span>

                  <span v-if="item.web_hook_synched === false">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="gray" v-bind="attrs" v-on="on"
                          >mdi-close-circle</v-icon
                        >
                      </template>
                      <span>Not synched or web hook not set</span>
                    </v-tooltip>
                  </span>
                </v-layout>
              </td>
              <td>{{ item.status }}</td>
              <td>
                <v-btn
                  color="primary"
                  class="details-btn"
                  text
                  small
                  @click="openDetails(item)"
                  >Show</v-btn
                >
              </td>
              <td>
                <v-menu
                  transition="slide-x-transition"
                  bottom
                  right
                  v-if="item.status === 'RECEIVED' && allowed && !item.web_hook_synched"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="!item.web_hook_synched"
                      @click="openReview('APPROVED', item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-check-bold</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Approve</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="!item.web_hook_synched"
                      @click="openReview('DENIED', item)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-close-thick</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Reject</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="webHookSet && !item.web_hook_synched"
                      @click="resend(item.request_uuid)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-send</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Resend</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <span
                  v-else-if="
                    item.status === 'APPROVED' || item.status === 'DENIED'
                  "
                >
                  Reviewed <v-icon small color="confirm">mdi-check-all</v-icon>
                </span>
                <span v-else-if="item.web_hook_synched">
                  Synched <v-icon small color="confirm">mdi-check-all</v-icon>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>

      <DarDetailsModal
        @close-details="closeDetails"
        :darInfo="details"
        :showDetails="detailsPopup"
        v-if="detailsPopup"
      />

      <DarReviewModal
        @submit-review="reviewDar"
        @close="closeReview"
        :trustedDts="trustedDar"
        :level="darLevel"
        :decision="decision"
        :rules="rules"
        :reviewModal="reviewPopup"
        v-if="reviewPopup"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import DarDetailsModal from "@/components/dialogs/DarDetailsModal.vue";
import DarReviewModal from "@/components/dialogs/DarReviewModal.vue";
import { mapState } from "vuex";
export default {
  name: "DarSection",
  components: {
    DarDetailsModal,
    DarReviewModal,
  },
  props: {
    admin_token: String,
    rules: Object,
    allowed: Boolean,
  },
  data: () => {
    return {
      darHeaders: [
        { text: "Project Code", value: "project_code" },
        { text: "User email", value: "requester.email" },
        { text: "Request date" },
        { text: "End date" },
        { text: "Trust Level" },
        { text: "Web Hook Synched", align: "center" },
        { text: "Status", value: "status" },
        { text: "Details" },
        { text: "Actions" },
      ],
      darList: [],
      darID: "",
      decision: "",
      reviewPopup: false,
      sort: true,
      detailsPopup: false,
      details: {},
      search: "",
      darLevel: null,
      trustedDar: false,
      pagination: {
        page: 1,
        rowsPerPage: 5,
      },
      totalItems: 0,
    };
  },
  methods: {
    async getDars() {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.get(
          `/api/admin/v1.0/data-access-requests?page=${this.pagination.page}&page_size=${this.pagination.rowsPerPage}`
        );
        this.darList = res.data.data;
        this.webHookSet = res.data.web_hook_configured;
        this.totalItems = res.data.total;
        this.darList.sort((a, b) => (a.status > b.status ? -1 : 1));
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    openReview(decision, dar) {
      this.trustedDar = dar.trusted_dataset;
      this.decision = decision;
      this.darID = dar.request_uuid;
      this.darLevel = dar.access_level;
      this.reviewPopup = true;
    },
    async reviewDar(resp) {
      this.reviewPopup = false;
      this.$store.commit("setLoader", true);
      const payload = {
        reviewer_uuid: this.$store.state.uuid,
        decision: this.decision,
        reason: resp.reason,
        trusted: resp.trusted,
        id: this.darID,
        webHookSet: false,
      };

      try {
        const res = await axios.post(
          `/api/admin/v1.0/data-access-requests/${this.darID}/review`,
          payload
        );
        console.log(res.status);
        swal("Confirmation", `DAR ${this.decision.toLowerCase()}!`, "success");
        this.getDars();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async resend(dar_uuid) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.get(
          `/api/admin/v1.0/data-access-requests/${dar_uuid}/resend`
        );
        console.log(res.status);
        swal("Confirmation", "Web hook synched successfully", "success");
        this.getDars();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.getDars();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.getDars();
    },
    openDetails(item) {
      this.details = item;
      this.detailsPopup = true;
    },
    closeDetails(val) {
      this.detailsPopup = val;
    },
    closeReview(val) {
      this.reviewPopup = val;
    },
  },
  mounted() {
    this.getDars();
  },
  computed: {
    ...mapState(["activeUserRole"]),
    filteredDars() {
      return this.darList.filter((dar) => {
        return (
          dar.project_code.toLowerCase().includes(this.search.toLowerCase()) ||
          dar.requester.email
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          dar.status.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
};
</script>
<style>
.btn-refresh {
  margin: auto 0 0 0;
}
.details-btn {
  padding: 0 !important;
}

.dar-actions {
  display: flex;
  justify-content: space-between;
}

.name-label {
  text-transform: capitalize;
}
.chip-text {
  font-size: 12px;
  color: #6a6666;
  font-weight: 700;
}
</style>

<template>
  <div>
    <v-card height="500" class="mb-5 ip-boards">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ description }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          solo
          outlined
          dense
          rounded
          hide-details
          v-model="filter"
          placeholder="search IP"
        ></v-text-field>
        <v-divider class="mt-3"></v-divider>
        <div class="whitelist-container">
          <v-list dense v-if="data.length > 0">
            <v-list-item v-for="(ip, i) in filteredIps" :key="i">
              <v-list-item-avatar>
                <v-icon class="mr-2"> mdi-ip-network </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                {{ ip }}
              </v-list-item-content>
              <v-list-item-action>
                <v-icon @click="removeIp(ip)">mdi-trash-can-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="no-ips-text" v-else>
            <p>
              There are no IPs associated to this whitelist. You can add IPs
              using the button below
            </p>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="newIpModal = true">Add IP</v-btn>
      </v-card-actions>
    </v-card>
    <AddIPModal
      @new-ip="addAllowedIP"
      @close-ip="closeNewIpModal"
      :openModal="newIpModal"
      v-if="newIpModal"
    />
  </div>
</template>
<script>
import { isIPValid } from "@/utils/ipValidator";
import axios from "axios";
import swal from "sweetalert";
import AddIPModal from "@/components/dialogs/AddIPModal.vue";
export default {
  name: "WhiteListBoard",
  components: {
    AddIPModal,
  },
  props: {
    title: String,
    category: String,
    description: String,
  },
  data() {
    return {
      filter: "",
      newIpModal: false,
      data: [],
    };
  },
  methods: {
    async getWhiteLists() {
      try {
        const res = await axios.get("/api/admin/v1.0/whitelists");
        this.data = res.data[this.category].allow;
      } catch (err) {
        console.log(err);
      }
    },
    addAllowedIP(ip) {
      if (isIPValid(ip)) {
        swal({
          title: "IP Confirmation",
          text: `Is this IP correct: ${ip}? Wrong IPs may prevent access to the appliance.`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.newIpModal = false;
            this.data.push(ip);
            this.saveChanges();
          }
        });
      } else {
        swal("Error", "You have entered an invalid IP address!", "error");
        return;
      }
    },
    async saveChanges() {
      const payload = {
        allow: this.data,
        deny: [],
      };
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.patch(
          `/api/admin/v1.0/whitelists/${this.category}`,
          payload
        );
        console.log(res.status);
        this.getWhiteLists();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    removeIp(ip) {
      swal({
        title: "Delete",
        text: `Are you sure you want to delete this IP: ${ip}`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.data.splice(this.data.indexOf(ip), 1);
          this.saveChanges();
        }
      });
    },
    closeNewIpModal(val) {
      this.newIpModal = val;
    },
  },
  computed: {
    filteredIps() {
      return this.data.filter((row) => {
        const varName = row.toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        return varName.includes(searchTerm);
      });
    },
  },
  mounted() {
    this.getWhiteLists();
  },
  watch: {
    newIpModal: function (value) {
      if (!value) {
        this.newIP = "";
      }
    },
  },
};
</script>
<style scoped>
.whitelist-container {
  height: 300px;
}
.v-list {
  height: 300px;
  overflow-y: auto;
}
.no-ips-text {
  padding: 30% 10%;
  text-align: center;
}
.ip-boards {
  width: 320px;
}
@media (max-width: 1480px) {
  .ip-boards {
    width: 280px;
  }
}
@media (max-width: 1347px) {
  .ip-boards {
    width: 230px;
  }
}
</style>

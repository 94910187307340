var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.userForm,
        callback: function ($$v) {
          _vm.userForm = $$v
        },
        expression: "userForm",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" New User "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-account")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newUserForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "px-15" },
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "user-email" } },
                    [_vm._v("Email")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      type: "email",
                      id: "user-email",
                      solo: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.email, _vm.rules.required],
                    },
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "user-name" } },
                    [_vm._v("Username")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      id: "user-name",
                      solo: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.required],
                      autocomplete: "new-username",
                    },
                    model: {
                      value: _vm.user.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "username", $$v)
                      },
                      expression: "user.username",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "cancel" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.addNewFdsaUser },
                    },
                    [_vm._v("Create")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
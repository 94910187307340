<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__title">
        <img
          src="../assets/warning-icon.png"
          height="20px"
          class="mr-1"
        />
        <span>Session Expired</span>
      </div>
      <v-divider></v-divider>
      <div class="p-3 mt-3">
        <p>You have left this browser idle for 20 minutes.</p>
        <p>You will be logged out in <b>{{ time / 1000 }}</b> seconds</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      time: 10000,
    };
  },
  methods: {
    ...mapActions(["logOut"]),
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.logOut();
      }
    }, 1000);
  },
};
</script>

<style lang="postcss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  font-family: "Lato";
}
.modal__title {
  color: #38404f;
  font-weight: bold;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 10px;

  /* padding:10px; */
}
</style>

import { render, staticRenderFns } from "./MainSection.vue?vue&type=template&id=7174c6ec&scoped=true"
import script from "./MainSection.vue?vue&type=script&lang=js"
export * from "./MainSection.vue?vue&type=script&lang=js"
import style0 from "./MainSection.vue?vue&type=style&index=0&id=7174c6ec&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7174c6ec",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VIcon,VImg,VTab,VTabItem,VTabs,VTabsItems,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7174c6ec')) {
      api.createRecord('7174c6ec', component.options)
    } else {
      api.reload('7174c6ec', component.options)
    }
    module.hot.accept("./MainSection.vue?vue&type=template&id=7174c6ec&scoped=true", function () {
      api.rerender('7174c6ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/MainSection.vue"
export default component.exports
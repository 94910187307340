var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app-bar", { attrs: { app: "", color: "black", dark: "" } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-end align-center" },
      [
        _c("v-img", {
          staticClass: "shrink mr-2",
          attrs: {
            alt: "ADDI Logo",
            contain: "",
            src: require("../assets/new-logo.svg"),
            width: "120px",
            height: "50px",
            transition: "scale-transition",
          },
        }),
        _c("v-divider", {
          staticClass: "ml-5 mr-5 divider-header",
          attrs: { vertical: "" },
        }),
        _c("v-toolbar-title", [
          _vm._v(
            "Federated Data Sharing Appliance - Administration Application"
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="loading-img">
      <v-img
        width="300"
        height="300"
        class="ma-auto"
        src="@/assets/Logo-loading2.gif"
      ></v-img>
    </div>

    <div>
      <v-img
        width="350"
        height="200"
        src="@/assets/loading.gif"
        class="ma-auto"
      ></v-img>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingScreen",
};
</script>
<style>
.loading-img{
  margin-top:100px
}
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overlay" }, [
    _c(
      "div",
      { staticClass: "modal" },
      [
        _vm._m(0),
        _c("v-divider"),
        _c("div", { staticClass: "p-3 mt-3" }, [
          _c("p", [_vm._v("You have left this browser idle for 20 minutes.")]),
          _c("p", [
            _vm._v("You will be logged out in "),
            _c("b", [_vm._v(_vm._s(_vm.time / 1000))]),
            _vm._v(" seconds"),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__title" }, [
      _c("img", {
        staticClass: "mr-1",
        attrs: { src: require("../assets/warning-icon.png"), height: "20px" },
      }),
      _c("span", [_vm._v("Session Expired")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "540" },
      model: {
        value: _vm.newSrvc,
        callback: function ($$v) {
          _vm.newSrvc = $$v
        },
        expression: "newSrvc",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Add service "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-eye-plus")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newServiceForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "label-form" }, [_vm._v("Name")]),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.required],
                      placeholder: "Name of the FDSA",
                    },
                    model: {
                      value: _vm.newService.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.newService, "name", $$v)
                      },
                      expression: "newService.name",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Organization name"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                              placeholder: "Hosting organization name",
                            },
                            model: {
                              value: _vm.newService.organization_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newService,
                                  "organization_name",
                                  $$v
                                )
                              },
                              expression: "newService.organization_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Organization url"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                              placeholder: "Organization's website",
                            },
                            model: {
                              value: _vm.newService.organization_url,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newService,
                                  "organization_url",
                                  $$v
                                )
                              },
                              expression: "newService.organization_url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Contact URL"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      placeholder:
                        "Provide users a means of contacting the host organization",
                    },
                    model: {
                      value: _vm.newService.contact_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.newService, "contact_url", $$v)
                      },
                      expression: "newService.contact_url",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Documentation URL"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      placeholder:
                        " Provide users with a link to FDSA documentation",
                    },
                    model: {
                      value: _vm.newService.documentation_url,
                      callback: function ($$v) {
                        _vm.$set(_vm.newService, "documentation_url", $$v)
                      },
                      expression: "newService.documentation_url",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Description"),
                  ]),
                  _c("v-textarea", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      rows: "3",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.newService.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.newService, "description", $$v)
                      },
                      expression: "newService.description",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "edit" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
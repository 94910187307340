<template>
  <div>
    <v-data-table :headers="webHookHeaders" :items="webHook" class="elevation-6 mt-3 webhooktable"
      :hide-default-footer="true">
      <template v-slot:top>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Data Access Requests Web Hook Configuration
            <v-tooltip right max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
              </template>
              <span>{{ description }}</span>
            </v-tooltip>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="#fff" outlined class="mb-2" @click="webHookModal = true" v-if="webHook.length === 0">
            Add Web Hook
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.url }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.updated_at }}</td>
          <td>
            <v-menu transition="slide-x-transition" bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item @click="openUpdateWebHook(item.url)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Update</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteWebHook(item)">
                  <v-list-item-icon>
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!-- Add web hook modal -->
    <AddWebHookModalVue :openModal="webHookModal" :rules="rules" v-if="webHookModal" @closeWH="closeNewWHModal"
      @addWH="addWebHook" />

    <!-- Edit web hook modal -->
    <EditWebHookModal @updateWH="updateWebHook" @close-edit="closeEditWebHook" :editableUrl="url"
      :openModal="editWebHookModal" v-if="editWebHookModal" />
  </div>
</template>

<script>
import axios from "axios";
import AddWebHookModalVue from "./dialogs/AddWebHookModal.vue";
import EditWebHookModal from "./dialogs/EditWebHookModal.vue";
export default {
  name: "WebHookSection",
  components: {
    AddWebHookModalVue,
    EditWebHookModal,
  },
  props: {
    rules: Object,
  },
  data() {
    return {
      webHookHeaders: [
        { text: "Endpoint", value: "url" },
        { text: "Created At", value: "created_at" },
        { text: "Updated at", value: "updated_at" },
        { text: "Actions" },
      ],
      webHook: [],
      webHookModal: false,
      editWebHookModal: false,
      url: "",
      description: `FDSA will automatically forward Data Access Request data to this webhook.This option enables data owners to easily integrate their own Data Access Approve/Reject services. Data owners will use the FDSA DAR Approve/Reject API https://${process.env.VUE_APP_FQDN}/api/admin/v1.0/data-access-requests/<request_id>/review to push their approval/rejection back to FDSA once their internal process is completed.Details for this feature are found in the AD Connect documentation`,
    };
  },
  methods: {
    async getWebHook() {
      const wh = axios.create({
        baseURL: `https://${process.env.VUE_APP_FQDN}`,
      });
      try {
        const res = await wh.get("/api/admin/v1.0/web_hook");
        if (Object.keys(res.data).length > 0) {
          this.webHook.push(res.data)
        } else {
          this.webHook = []
        }
      } catch (err) {
        console.log(err.response.data.error);
      }
    },
    async addWebHook(url) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.patch("/api/admin/v1.0/web_hook", url);
        console.log(res.status);
        this.getWebHook();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
        this.webHookModal = false;
      }
    },
    openUpdateWebHook(targetUrl) {
      this.url = targetUrl;
      this.editWebHookModal = true;
    },
    async updateWebHook(url) {
      this.$store.commit("setLoader", true);
      const payload = { url };

      try {
        const res = await axios.patch("/api/admin/v1.0/web_hook", payload);
        console.log(res.status);
        this.getWebHook();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
        this.editWebHookModal = false;
      }
    },
    async deleteWebHook(wh) {
      try {
        const res = await axios.delete(`/api/admin/v1.0/web_hook/${wh.id}`);
        console.log(res.status);
        this.getWebHook();
      } catch (err) {
        console.log(err);
      }
    },
    closeNewWHModal(val) {
      this.webHookModal = val;
    },
    closeEditWebHook(val) {
      this.editWebHookModal = val;
    },
  },
  mounted() {
    this.getWebHook();
  },
};
</script>
<style scoped>
.webhooktable {
  margin-bottom: 50px;
}
</style>

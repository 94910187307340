<template>
  <v-dialog width="500" persistent v-model="userForm">
    <v-card color="#efefef">
      <v-card-title>
        New User
        <v-icon class="ml-3" color="primary">mdi-account</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newUserForm">
        <v-card-text class="px-15">
          <label class="label-form" for="user-email">Email</label>
          <v-text-field
            type="email"
            id="user-email"
            solo
            outlined
            dense
            v-model="user.email"
            :rules="[rules.email, rules.required]"
          ></v-text-field>
          <label class="label-form" for="user-name">Username</label>
          <v-text-field
            id="user-name"
            solo
            outlined
            dense
            v-model="user.username"
            :rules="[rules.required]"
            autocomplete="new-username"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="closeModal">Close</v-btn>
          <v-btn color="confirm" dark @click="addNewFdsaUser">Create</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddUserForm",
  props: {
    rules: Object,
    addUserModal: Boolean,
  },
  data() {
    return {
      user: {
        temporary: true,
        mfa_required: true,
      },
      userForm: false,
    };
  },
  methods: {
    addNewFdsaUser() {
      if (!this.$refs.newUserForm.validate()) {
        return;
      }
      this.$emit("add-user", this.user);
    },
    closeModal() {
      this.userForm = false;
      this.$emit("close", this.userForm);
    },
  },
  created() {
    this.userForm = this.addUserModal;
  },
};
</script>

<template>
  <v-dialog v-model="updateSrvModal" persistent width="540">
    <v-card color="#efefef">
      <v-card-title>
        Edit service
        <v-icon color="primary" class="ml-3">mdi-pencil</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="editServiceForm">
        <v-card-text>
          <label class="label-form">Name</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="serviceToEdit.name"
          ></v-text-field>
          <v-row>
            <span
              v-for="(value, name, index) in serviceToEdit.organization"
              :key="index"
            >
              <v-col>
                <label class="label-form">Organization {{ name }}</label>
                <v-text-field
                  solo
                  outlined
                  dense
                  v-model="serviceToEdit.organization[name]"
                ></v-text-field>
              </v-col>
            </span>
          </v-row>
          <label class="label-form">Contact URL</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="serviceToEdit.contactUrl"
          ></v-text-field>
          <label class="label-form">Documentation URL</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="serviceToEdit.documentationUrl"
          ></v-text-field>
          <label class="label-form">Description</label>
          <v-textarea
            solo
            outlined
            rows="3"
            v-model="serviceToEdit.description"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="edit" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="saveChanges">Edit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'EditServiceInfoModal',
    props:{
        openModal:Boolean,
        service:Object,
    },
    data() {
        return {
            serviceToEdit:{},
            updateSrvModal:false,
        };
    },

    created() {
        this.serviceToEdit = this.service;
        this.updateSrvModal = this.openModal;
    },

    methods: {
        saveChanges(){
            this.$emit('update', this.serviceToEdit);
        },
        close(){
            this.updateSrvModal = false;
            this.$emit('close-edit', this.updateSrvModal);
        }
    },
};
</script>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "450", persistent: "" },
      model: {
        value: _vm.addNewRegistryModal,
        callback: function ($$v) {
          _vm.addNewRegistryModal = $$v
        },
        expression: "addNewRegistryModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Add new registry "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-dns")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "regForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "regAdress" } },
                    [_vm._v("Registry Address")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "regAdress",
                      prefix: "https://",
                    },
                    model: {
                      value: _vm.newRegistry.registry_address,
                      callback: function ($$v) {
                        _vm.$set(_vm.newRegistry, "registry_address", $$v)
                      },
                      expression: "newRegistry.registry_address",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "reguser" } },
                    [_vm._v("Registry Username")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "reguser",
                      autocomplete: "new-username",
                    },
                    model: {
                      value: _vm.newRegistry.registry_username,
                      callback: function ($$v) {
                        _vm.$set(_vm.newRegistry, "registry_username", $$v)
                      },
                      expression: "newRegistry.registry_username",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "regpass" } },
                    [_vm._v("Registry Password")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "regpass",
                      type: "password",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.newRegistry.registry_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.newRegistry, "registry_password", $$v)
                      },
                      expression: "newRegistry.registry_password",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
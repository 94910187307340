<template>
  <v-dialog v-model="taskHistoryModal" width="700" persistent>
    <v-card>
      <v-card-title>
        Task History
        <v-icon color="primary" class="ml-3">mdi-briefcase-eye</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="history-container">
        <v-simple-table class="mt-5">
          <thead>
            <tr>
              <th class="history-headers">Updated</th>
              <th class="history-headers">State</th>
              <th class="history-headers">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(history, index) in histories" :key="index">
              <td>{{ history.updated_at }}</td>
              <td>{{ history.state }}</td>
              <td>{{ history.details }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn color="cancel" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    name: 'TaskHistoryModal',
    props:{
        showHistory:Boolean,
        taskHistory:Array
    },

    data() {
        return {
            taskHistoryModal:false,
            histories:[]
        };
    },

    created() {
        this.histories = this.taskHistory;
        this.taskHistoryModal = this.showHistory;
    },

    methods: {
        close(){
            this.taskHistoryModal = false;
            this.$emit('close-history', this.taskHistoryModal);
        }
    },
};
</script>
<template>
  <v-dialog v-model="trackLoader" width="350" height="300">
    <v-card class="pa-3">
      <v-card-text class="text-center">
        <p class="loading-text">Tracking relationships...</p>
        <v-img
          class="mt-2 mx-auto"
          src="@/assets/Logo-loading2.gif"
          height="50"
          width="50"
        ></v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TrackLoader",
  data() {
    return {
      trackLoader: true,
    };
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1250", persistent: "" },
      model: {
        value: _vm.datasetsModal,
        callback: function ($$v) {
          _vm.datasetsModal = $$v
        },
        expression: "datasetsModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_c("span", [_vm._v("Datasets ")])]),
          _c("v-divider"),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "datasets-container" },
              [
                _c("v-data-table", {
                  staticClass: "mt-3",
                  attrs: {
                    items: _vm.datasetList,
                    headers: _vm.datasetHeaders,
                    "items-per-page": _vm.pagination.rowsPerPage,
                    page: _vm.pagination.page,
                    "server-items-length": _vm.totalItems,
                    "footer-props": {
                      itemsPerPageOptions: [5, 10, 15, 20, 25],
                    },
                  },
                  on: {
                    "update:itemsPerPage": function ($event) {
                      return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                    },
                    "update:items-per-page": [
                      function ($event) {
                        return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                      },
                      _vm.onItemsPerPageChange,
                    ],
                    "update:page": [
                      function ($event) {
                        return _vm.$set(_vm.pagination, "page", $event)
                      },
                      _vm.onPageChange,
                    ],
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("tr", [
                            _c("td", [_vm._v(_vm._s(item.dataset_id))]),
                            _c(
                              "td",
                              [
                                _c("span", [_vm._v(_vm._s(item.dataset_uuid))]),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-2 copy-icon",
                                    attrs: { small: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyUuid(item.dataset_uuid)
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(item.title))]),
                            _c("td", [_vm._v(_vm._s(item.schema_name))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                item.catalogue !== null
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v("mdi-check-circle")]
                                    )
                                  : _c("v-icon", { attrs: { color: "gray" } }, [
                                      _vm._v("mdi-close-circle"),
                                    ]),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                item.trusted
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "#0af215" } },
                                      [_vm._v("mdi-check")]
                                    )
                                  : _c(
                                      "v-icon",
                                      { attrs: { color: "#f2190a" } },
                                      [_vm._v("mdi-close")]
                                    ),
                              ],
                              1
                            ),
                            _c("td", [
                              item.dataset_level === "level1"
                                ? _c("span", [_vm._v("Remote query")])
                                : _c("span", [_vm._v("Federated query")]),
                            ]),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      transition: "slide-x-transition",
                                      bottom: "",
                                      right: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-dots-vertical")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeDatasetPrivacy(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                item.trusted
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-shield-lock-outline"
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "green",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-shield-lock-open"
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c("v-list-item-title", [
                                              item.trusted
                                                ? _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        color: "#f2190a",
                                                      },
                                                    },
                                                    [_vm._v("Untrust")]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        color: "#0af215",
                                                      },
                                                    },
                                                    [_vm._v("Trust")]
                                                  ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.track(
                                                  item.dataset_id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-database-sync"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-list-item-title", [
                                              _vm._v(" Refresh Dataset "),
                                            ]),
                                          ],
                                          1
                                        ),
                                        item.catalogue
                                          ? _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openEditCatalogue(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-book-open-variant"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v("Show Catalogue"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openAddCatalogue(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-book-open-variant"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v("Add Catalogue"),
                                                ]),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteDts(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "error" } },
                                                  [_vm._v("mdi-database-minus")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "delete-option" },
                                              [_vm._v("Delete")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-5",
                              attrs: { text: "", color: "primary", small: "" },
                              on: { click: _vm.newDataset },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(" Add a new Dataset"),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mb-0",
          attrs: { dense: "", type: "success", value: _vm.alert },
        },
        [_vm._v("Dataset UUID copied!")]
      ),
      _vm.addCatalogue
        ? _c("AddCatalogueModal", {
            attrs: {
              openModal: _vm.addCatalogue,
              rules: _vm.rules,
              trustedDataset: _vm.trustedDataset,
            },
            on: {
              "close-add-catalogue": _vm.closeAddCatalogue,
              saveCatalogue: _vm.saveAddCatalogue,
            },
          })
        : _vm._e(),
      _vm.editCat
        ? _c("EditCatalogueModal", {
            attrs: {
              openModal: _vm.editCat,
              rules: _vm.rules,
              trustedDataset: _vm.trustedDataset,
              catalogue: _vm.targetCat,
            },
            on: {
              "close-edit-cat": _vm.closeEditCatalogue,
              "save-edit": _vm.saveUpdateCatalogue,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
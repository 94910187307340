<template>
  <v-dialog v-model="newDataSourceModal" persistent width="700">
    <v-card color="#efefef">
      <v-card-title>
        Add a new data source
        <v-icon color="primary" class="ml-3">mdi-database</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newDtSrcForm">
        <v-card-text>
          <v-row>
            <v-col md="6" sm="6"
              ><label class="label-form">Database type</label>
              <v-select
                :items="databaseTypesOptions"
                outlined
                v-model="newDataSource.database_type"
                solo
                dense
                :rules="[rules.required]"
              ></v-select>
              <label class="label-form">Name (friendly name)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newDataSource.name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col md="6" sm="6">
              <label class="label-form">Host (FQDN or IPs only)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newDataSource.host"
                placeholder="postgres.db.org"
                :rules="[rules.required]"
              ></v-text-field>
              <label class="label-form">Port</label>
              <v-text-field
                type="number"
                solo
                outlined
                dense
                min="0"
                v-model.number="portNumber"
                :rules="[rules.required]"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <label class="label-form">Database Name (case sensitive)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newDataSource.database_name"
                :rules="[rules.required]"
              ></v-text-field>
              <label class="label-form">Database User (case sensitive)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newDataSource.database_user"
                autocomplete="new-username"
                :rules="[rules.required, rules.notAllowedUser]"
              ></v-text-field>
              <label class="label-form"
                >Database Password (case sensitive)</label
              >
              <v-text-field
                solo
                outlined
                dense
                v-model="newDataSource.database_password"
                autocomplete="new-password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="label-form">Description</label>
              <v-textarea
                solo
                outlined
                dense
                v-model="newDataSource.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close"
            >Cancel</v-btn
          >
          <v-btn outlined color="confirm" @click="testConnection">Test</v-btn>
          <v-btn class="white--text" color="confirm" @click="addDataSource" :disabled="!save">Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  name: "AddDatasourceModal",

  props: {
    openModal: Boolean,
    rules:Object
  },

  data() {
    return {
      databaseTypesOptions: ["PostgreSQL"],
      newDataSource: {},
      newDataSourceModal: false,
      showPass:false,
      portNumber: 5432,
      save:false,
    };
  },

  methods: {
    addDataSource() {
      if(!this.$refs.newDtSrcForm.validate()){
        return;
      }
      this.newDataSource.port = this.portNumber;
      this.$emit("new-ds", this.newDataSource);
    },
    close() {
      this.newDataSourceModal = false;
      this.$emit("close-ds", this.newDataSourceModal);
    },
    async testConnection(){
      if(!this.$refs.newDtSrcForm.validate()){
        return;
      }
      this.$store.commit("setLoader", true);
      try {
        this.newDataSource.port = this.portNumber;
        const res =  await axios.post("/api/admin/v1.0/datasources/check", this.newDataSource);
        this.save = true;
        swal("Success!", res.data.message , "success");
      } catch (error) {
        console.log(error);
        swal("Error!", error.response.data.message, "error");
      }finally{
        this.$store.commit("setLoader", false);
      }
    }
  },

  created() {
    this.newDataSourceModal = this.openModal;
  },

  watch: {
    newDataSourceModal: function (value) {
      if (!value) {
        this.$refs.newDtSrcForm.reset();
      }
    },
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: "" },
      model: {
        value: _vm.rolesModal,
        callback: function ($$v) {
          _vm.rolesModal = $$v
        },
        expression: "rolesModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("User roles")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-list",
                { attrs: { rounded: "" } },
                [
                  _c("p", { staticClass: "text-center instructions" }, [
                    _vm._v(
                      " Please select or deselect the role you want for this user "
                    ),
                  ]),
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.roleSelected,
                        callback: function ($$v) {
                          _vm.roleSelected = $$v
                        },
                        expression: "roleSelected",
                      },
                    },
                    _vm._l(_vm.roleList, function (item, index) {
                      return _c(
                        "v-list-item",
                        { key: index, staticClass: "roles" },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              item === "administrator"
                                ? _c("v-icon", [_vm._v("mdi-account-cog")])
                                : item === "standard"
                                ? _c("v-icon", [_vm._v("mdi-account")])
                                : _c("v-icon", [
                                    _vm._v("mdi-account-arrow-left"),
                                  ]),
                            ],
                            1
                          ),
                          _c("label", { staticClass: "ml-3 label-form" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { on: { click: _vm.close } }, [_vm._v("Close")]),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "confirm" },
                  on: { click: _vm.updateRole },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("appHeader"),
      _c(
        "v-main",
        [
          _c("router-view"),
          _c(
            "v-dialog",
            {
              attrs: { width: "350", height: "300" },
              model: {
                value: _vm.loader,
                callback: function ($$v) {
                  _vm.loader = $$v
                },
                expression: "loader",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-3" },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c("p", { staticClass: "loading-text" }, [
                        _vm._v("Loading..."),
                      ]),
                      _c("v-img", {
                        staticClass: "mt-2 mx-auto",
                        attrs: {
                          src: require("@/assets/Logo-loading2.gif"),
                          height: "50",
                          width: "50",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
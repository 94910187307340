var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "" },
      model: {
        value: _vm.newCatalogueModal,
        callback: function ($$v) {
          _vm.newCatalogueModal = $$v
        },
        expression: "newCatalogueModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c("v-card-title", { staticClass: "d-flex justify-space-between" }, [
            _c(
              "div",
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-3", attrs: { color: "primary" } },
                  [_vm._v("mdi-book-open-variant")]
                ),
                _vm._v(" Add Catalogue "),
              ],
              1
            ),
            _vm.trustedDataset
              ? _c(
                  "span",
                  { staticClass: "trust-title" },
                  [
                    _vm._v("Trusted Dataset "),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-3",
                        attrs: { color: "green", small: "" },
                      },
                      [_vm._v("mdi-lock-open-variant")]
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "trust-title" },
                  [
                    _vm._v("Untrusted Dataset "),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-3",
                        attrs: { color: "red", small: "" },
                      },
                      [_vm._v("mdi-lock")]
                    ),
                  ],
                  1
                ),
          ]),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "catalogueForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "5", md: "5", sm: "12", xs: "12" } },
                            [
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "title" },
                                  },
                                  [_vm._v("Title")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      id: "title",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.title,
                                      callback: function ($$v) {
                                        _vm.title = $$v
                                      },
                                      expression: "title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                lg: "5",
                                md: "5",
                                sm: "12",
                                xs: "12",
                                "offset-md": "1",
                              },
                            },
                            [
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "version" },
                                  },
                                  [_vm._v("Version")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.version,
                                      callback: function ($$v) {
                                        _vm.version = $$v
                                      },
                                      expression: "version",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "5", md: "5", sm: "12", xs: "12" } },
                            [
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "description" },
                                  },
                                  [_vm._v("Description")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      solo: "",
                                      outlined: "",
                                      dense: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c("label", { staticClass: "label-form" }, [
                                  _vm._v("Publication date"),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dense: "",
                                                        solo: "",
                                                        outlined: "",
                                                        placeholder:
                                                          "Select date",
                                                        "prepend-inner-icon":
                                                          "mdi-calendar",
                                                        readonly: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.publicationDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.publicationDate =
                                                            $$v
                                                        },
                                                        expression:
                                                          "publicationDate",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menu,
                                        callback: function ($$v) {
                                          _vm.menu = $$v
                                        },
                                        expression: "menu",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        on: {
                                          input: function ($event) {
                                            _vm.menu = false
                                          },
                                        },
                                        model: {
                                          value: _vm.publicationDate,
                                          callback: function ($$v) {
                                            _vm.publicationDate = $$v
                                          },
                                          expression: "publicationDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                lg: "5",
                                md: "5",
                                sm: "12",
                                xs: "12",
                                "offset-md": "1",
                              },
                            },
                            [
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "author" },
                                  },
                                  [_vm._v("Author")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      rules: [_vm.rules.required],
                                      id: "author",
                                    },
                                    model: {
                                      value: _vm.creator,
                                      callback: function ($$v) {
                                        _vm.creator = $$v
                                      },
                                      expression: "creator",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "contact-email" },
                                  },
                                  [_vm._v("Contact Email")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      id: "contact-email",
                                      rules: [
                                        _vm.rules.email,
                                        _vm.rules.required,
                                      ],
                                    },
                                    model: {
                                      value: _vm.contactPoint.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contactPoint, "email", $$v)
                                      },
                                      expression: "contactPoint.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "contact-name" },
                                  },
                                  [_vm._v("Contact Name")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      id: "contact-name",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.contactPoint.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.contactPoint, "name", $$v)
                                      },
                                      expression: "contactPoint.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "5", md: "5", sm: "12", xs: "12" } },
                            [
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "license" },
                                  },
                                  [_vm._v("License")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      id: "license",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.license,
                                      callback: function ($$v) {
                                        _vm.license = $$v
                                      },
                                      expression: "license",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "label-form",
                                    attrs: { for: "access" },
                                  },
                                  [_vm._v("Rights (Dataset terms of use)")]
                                ),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      rules: [_vm.rules.required],
                                      id: "access",
                                    },
                                    model: {
                                      value: _vm.accessRights,
                                      callback: function ($$v) {
                                        _vm.accessRights = $$v
                                      },
                                      expression: "accessRights",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                lg: "5",
                                md: "5",
                                sm: "12",
                                xs: "12",
                                "offset-md": "1",
                              },
                            },
                            [
                              _c("v-row", [
                                _c("label", { staticClass: "label-form" }, [
                                  _vm._v("Publisher"),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.publisher.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.publisher, "name", $$v)
                                      },
                                      expression: "publisher.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", [
                                _c("label", { staticClass: "label-form" }, [
                                  _vm._v("Publisher URL"),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.publisher.url,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.publisher, "url", $$v)
                                      },
                                      expression: "publisher.url",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "justify-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "5", md: "5", sm: "12", xs: "12" } },
                            [
                              _c("v-row", [
                                _c("h5", { staticClass: "ml-3 mb-3" }, [
                                  _vm._v("Keywords"),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      placeholder:
                                        "Add keywords separated by commas",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.newKeyword,
                                      callback: function ($$v) {
                                        _vm.newKeyword = $$v
                                      },
                                      expression: "newKeyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { color: "primary" },
                                      on: { click: _vm.addKeyWord },
                                    },
                                    [_vm._v("Add")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                lg: "5",
                                md: "5",
                                sm: "12",
                                xs: "12",
                                "offset-md": "1",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-3" },
                                [
                                  _vm._l(
                                    _vm.keywords,
                                    function (keyword, index) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: index,
                                          staticClass: "mb-2 mr-2",
                                          attrs: {
                                            close: "",
                                            color: "blue",
                                            dark: "",
                                          },
                                          on: {
                                            "click:close": function ($event) {
                                              return _vm.deleteKeyword(index)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(keyword))]
                                      )
                                    }
                                  ),
                                  _vm.keywords.length === 0
                                    ? _c(
                                        "p",
                                        { staticClass: "text-center kw-text" },
                                        [_vm._v(" No keywords added ")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", dark: "" },
                  on: { click: _vm.save },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
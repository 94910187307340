import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
      minifyTheme
    },
    themes: {
      light: {
        primary: "#2F79DC",
        secondary: "#2D73D1",
        third: "#102c52",
        black: "#000",
        tableBg: "#F9FAFB",
        tableBdr: "#222426",
        error: "#850F19",
        comment: "#FF4DED",
        confirm: "#3C8556",
        // info: '#2196F3',
        success: "#4CAF50",
        cancel: "#e3e4e6",
        filter: "#f7fdff",
        warning: "#FFC107",
      },
    },
  },
});

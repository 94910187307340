<template>
  <v-dialog v-model="addStmpModal" width="600" persistent>
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-3">mdi-email</v-icon>
        Add SMTP Configuration
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newSMTPForm">
        <v-card-text>
          <label class="label-form" for="server">Mail server</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="smtpConfig.mail_server"
            id="server"
            :rules="[rules.required]"
            placeholder="smtp.yourserver.com"
          ></v-text-field>
          <label class="label-form" for="port">Mail Port</label>
          <v-text-field
            type="number"
            solo
            outlined
            dense
            id="port"
            v-model="smtpConfig.mail_port_str"
            :rules="[rules.required]"
            placeholder="Example: 587"
          ></v-text-field>
          <label class="label-form" for="sender">Mail default sender</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="smtpConfig.mail_default_sender"
            id="sender"
            :rules="[rules.required]"
            placeholder="example@email.io"
          ></v-text-field>
          <label class="label-form" for="mail_username">Mail username</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="smtpConfig.mail_username"
            id="mail_username"
            :rules="[rules.required]"
            placeholder="Your username"
          ></v-text-field>
          <label class="label-form" for="mail_pass">Mail password</label>
          <v-text-field
            solo
            dense
            outlined
            id="mail_pass"
            v-model="smtpConfig.mail_password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :rules="[rules.required]"
            placeholder="Your password"
          ></v-text-field>
          <label class="label-form" for="display_name">Mail display name</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="smtpConfig.mail_display_name"
            id="display_name"
            placeholder="Sender name"
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form" for="mail_use">Mail protocol</label>
          <v-select
            :items="protocolOptions"
            outlined
            v-model="protocolDecision"
            solo
            dense
            :rules="[rules.required]"
            placeholder="Select a protocol"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn dark color="confirm" @click="saveChanges">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddSMTPConfig",
  props: {
    openModal: Boolean,
    rules: Object,
  },
  data() {
    return {
      showPass: false,
      addStmpModal: false,
      protocolOptions: ["TLS", "SSL"],
      protocolDecision: "",
      smtpConfig: {},
    };
  },

  mounted() {
    this.addStmpModal = this.openModal;
  },

  methods: {
    saveChanges() {
      if (!this.$refs.newSMTPForm.validate()) {
        return;
      }
      if (this.protocolDecision === "SSL") {
        this.smtpConfig.mail_use_ssl = true;
        this.smtpConfig.mail_use_tls = false;
      } else {
        this.smtpConfig.mail_use_ssl = false;
        this.smtpConfig.mail_use_tls = true;
      }
      this.smtpConfig.mail_port = this.smtpConfig.mail_port_str * 1;
      this.$emit('save-smtp', this.smtpConfig);
    },
    close() {
        this.addStmpModal =false;
        this.$emit('close-smtp', this.addStmpModal);
    },
  },
  watch: {
    addStmpModal: function (value) {
      if (!value) {
        this.$refs.newSMTPForm.reset();
      }
    },
  },
};
</script>

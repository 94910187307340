var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.reviewTaskModal,
        callback: function ($$v) {
          _vm.reviewTaskModal = $$v
        },
        expression: "reviewTaskModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v("Review Task "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-briefcase-eye")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "reviewForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Outcome"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      items: _vm.outcomeOptions,
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.reviewPayload.outcome,
                      callback: function ($$v) {
                        _vm.$set(_vm.reviewPayload, "outcome", $$v)
                      },
                      expression: "reviewPayload.outcome",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Reason"),
                  ]),
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      solo: "",
                      cols: "60",
                      rows: "5",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.reviewPayload.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.reviewPayload, "reason", $$v)
                      },
                      expression: "reviewPayload.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "confirm", dark: "" },
                  on: { click: _vm.reviewCurrentTask },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-dialog v-model="sendSupportModal" width="500" persistent>
    <v-card color="#efefef">
      <v-card-title>
        <v-icon color="primary" class="mr-2">mdi-face-agent</v-icon>
        Support request
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="supportForm">
        <v-card-text>
          <label class="label-form" for="subject">Subject</label>
          <v-text-field
            v-model="bodyMessage.subject"
            solo
            dense
            outlined
            placeholder="Issue"
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form" for="subject">Message</label>
          <v-textarea
            outlined
            solo
            v-model="bodyMessage.message"
            :rules="[rules.required]"
            placeholder="Describe your issue here..."
            class="message-body"
          ></v-textarea>
          <v-switch
            v-model="logs"
            color="primary"
            label="Send logs"
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">cancel</v-btn>
          <v-btn dark color="confirm" @click="sendSupportEmail">Send</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  name: "SupportEmailModal",
  props: {
    openModal: Boolean,
    rules: Object,
  },
  data() {
    return {
      bodyMessage: {},
      sendSupportModal: false,
      logs: false,
    };
  },

  mounted() {
    this.sendSupportModal = this.openModal;
  },

  methods: {
    async sendSupportEmail() {
      if (!this.$refs.supportForm.validate()) {
        return;
      }
      this.$store.commit("setLoader", true);
      try {
        this.bodyMessage.send_logs = this.logs;
        const res = await axios.post(
          "/api/admin/v1.0/support_email",
          this.bodyMessage
        );
        swal("Confirmation", res.data.message, "success");
      } catch (err) {
        const message = err.response.data.error || err.response.data.errors;
        swal("Error", message, "error");
      }
      this.$store.commit("setLoader", false);
      this.close();
    },
    close() {
      this.sendSupportEmail = false;
      this.$emit("close-support", this.sendSupportEmail);
    },
  },
  watch: {
    sendSupportModal: function (value) {
      if (!value) {
        this.$refs.supportForm.reset();
      }
    },
  },
};
</script>
<style scoped>
.message-body {
  margin: 1px 0;
}
</style>

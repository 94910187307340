<template>
  <v-dialog v-model="newSrvc" persistent width="540">
    <v-card color="#efefef">
      <v-card-title>
        Add service
        <v-icon color="primary" class="ml-3">mdi-eye-plus</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newServiceForm">
        <v-card-text>
          <label class="label-form">Name</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newService.name"
            :rules="[rules.required]"
            placeholder="Name of the FDSA"
          ></v-text-field>
          <v-row>
            <v-col>
              <label class="label-form">Organization name</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newService.organization_name"
                :rules="[rules.required]"
                placeholder="Hosting organization name"
              ></v-text-field>
            </v-col>
            <v-col>
              <label class="label-form">Organization url</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="newService.organization_url"
                :rules="[rules.required]"
                placeholder="Organization's website"
              ></v-text-field>
            </v-col>
          </v-row>
          <label class="label-form">Contact URL</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newService.contact_url"
            placeholder="Provide users a means of contacting the host organization"
          ></v-text-field>
          <label class="label-form">Documentation URL</label>
          <v-text-field
            solo
            outlined
            dense
            v-model="newService.documentation_url"
            placeholder=" Provide users with a link to FDSA documentation"
          ></v-text-field>
          <label class="label-form">Description</label>
          <v-textarea
            solo
            outlined
            rows="3"
            v-model="newService.description"
            :rules="[rules.required]"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="edit" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="saveChanges">Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "NewServiceInfoModal",
  props: {
    rules: Object,
    openModal: Boolean,
  },
  data() {
    return {
      newService: {},
      newSrvc: false,
    };
  },

  created() {
    this.newSrvc = this.openModal;
  },

  methods: {
    saveChanges() {
      if (!this.$refs.newServiceForm.validate()) {
        return;
      }
      this.$emit('new-service', this.newService);
    },
    close(){
        this.newSrvc = false;
        this.$emit('close-service', this.newSrvc);
    }
  },
  watch: {
    newSrvc: function (value) {
      if (!value) {
        this.$refs.newServiceForm.reset();
      }
    },
  },
};
</script>

<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-15">
      <div class="list-container">
        <div class="d-flex justify-space-between flex-wrap">
          <WhiteListBoardVue
            :description="adminDesc"
            :category="'admin'"
            :title="'Admin Whitelist'"
          />

          <WhiteListBoardVue
            :description="authDesc"
            :category="'auth'"
            :title="'Keycloak Whitelist'"
            class="auth"
          />

          <WhiteListBoardVue
            :description="apiDesc"
            :category="'api'"
            :title="'DARs API Whitelist'"
            class="admin"
          /> 

          <WhiteListBoardVue
            :description="commonDesc"
            :category="'common'"
            :title="'Common API Whitelist'"
          />
        </div>

        <div class="smtp-token">
          <SMTPConfig :rules="rules" />
          <div>
            <WebHookSectionVue :rules="rules" />
            <TokenLifetimeSection />
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import WhiteListBoardVue from "./WhiteListBoard.vue";
import WebHookSectionVue from "./WebHookSection.vue";
import SMTPConfig from "./SMTPConfig.vue";
import TokenLifetimeSection from "./TokenLifetimeSection.vue";
export default {
  name: "SettingSection",
  props: {
    rules: Object,
  },
  data() {
    return {
      authDesc: "List that specifies which IPs can access the Keycloak UI.",
      apiDesc: "List that specifies which IPs can use the Data Access Requests API. The default IPs listed under this box belongs to ADWB FAIR.",
      commonDesc: "List that specifies which IPs can use the Common APIs",
      adminDesc:
        "List that specifies which IPs can use the FDSA GUI and Admin APIs.",
    };
  },
  components: {
    WhiteListBoardVue,
    WebHookSectionVue,
    SMTPConfig,
    TokenLifetimeSection,
  },
};
</script>
<style scoped>
.list-container {
  margin-top: 15px;
}
.smtp-token{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 12px;
}
@media (max-width:1430px) {
  .smtp-token{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>

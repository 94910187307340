var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c("v-card-text", { staticClass: "px-10" }, [
        _c(
          "div",
          [
            _c("v-data-table", {
              staticClass: "elevation-6 mt-10 mb-10",
              attrs: {
                headers: _vm.dataSourceHeaders,
                items: _vm.dataSourceList,
                "header-props": { sortIcon: null },
                "items-per-page": _vm.pagination.rowsPerPage,
                page: _vm.pagination.page,
                "footer-props": {
                  itemsPerPageOptions: [5, 15, 25, 50],
                },
                "disable-sort": true,
                "server-items-length": _vm.totalItems,
              },
              on: {
                "update:itemsPerPage": function ($event) {
                  return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                },
                "update:items-per-page": [
                  function ($event) {
                    return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                  },
                  _vm.onItemsPerPageChange,
                ],
                "update:page": [
                  function ($event) {
                    return _vm.$set(_vm.pagination, "page", $event)
                  },
                  _vm.onPageChange,
                ],
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(item.datasource_id))]),
                        _c("td", [_vm._v(_vm._s(item.database_type))]),
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _c("td", [_vm._v(_vm._s(item.description))]),
                        _c("td", [_vm._v(_vm._s(item.host))]),
                        _c("td", [_vm._v(_vm._s(item.port))]),
                        _c("td", [_vm._v(_vm._s(item.database_name))]),
                        _c("td", [_vm._v(_vm._s(item.database_user))]),
                        _c(
                          "td",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  transition: "slide-x-transition",
                                  bottom: "",
                                  right: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v("mdi-dots-vertical")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkDataSource(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-database-eye"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Check"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.addDataset(
                                              item.datasource_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-database-plus"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Add Dataset"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.getDatasets(
                                              item.datasource_id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-database-search"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Show Datasets"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.editDataSource(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-database-edit"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Update"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteDataSource(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "error" } },
                                              [_vm._v("mdi-database-minus")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-title",
                                          { staticClass: "delete-option" },
                                          [_vm._v("Delete")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-5",
                          attrs: { text: "", color: "primary", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.newDataSourcePopup = true
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-plus")]),
                          _vm._v(" Add a new Data Source"),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _vm.newDataSourcePopup
              ? _c("AddDatasourceModal", {
                  attrs: {
                    rules: _vm.rules,
                    openModal: _vm.newDataSourcePopup,
                  },
                  on: {
                    "new-ds": _vm.createNewDatasource,
                    "close-ds": _vm.closeNewDsModal,
                  },
                })
              : _vm._e(),
            _vm.updateDataSourcePopup
              ? _c("UpdateDatasourceModal", {
                  attrs: {
                    editDs: _vm.editableDs,
                    openModal: _vm.updateDataSourcePopup,
                    rules: _vm.rules,
                  },
                  on: {
                    update: _vm.updateDataSource,
                    "close-edit": _vm.closeUpdateDatasource,
                  },
                })
              : _vm._e(),
            _vm.addDatasetPopup
              ? _c("AddDatasetModal", {
                  attrs: {
                    openModal: _vm.addDatasetPopup,
                    id: _vm.dtId,
                    schemas: _vm.schemas,
                    rules: _vm.rules,
                  },
                  on: {
                    "new-ds": _vm.saveNewDataset,
                    "close-new-ds": _vm.closeAddDataset,
                  },
                })
              : _vm._e(),
            _vm.datasetsByIdPopup
              ? _c("ShowDatasetsModal", {
                  attrs: {
                    datasets: _vm.datasetsById,
                    datasourceId: _vm.datasourceId,
                    openModal: _vm.datasetsByIdPopup,
                    datasetPagination: _vm.datasetPagination,
                    datasetTotal: _vm.totalDatasets,
                    rules: _vm.rules,
                  },
                  on: {
                    "dts-privacy": _vm.changeDatasetPrivacy,
                    "dts-level": _vm.changeDsLevel,
                    refresh: _vm.getDatasets,
                    "close-show-dts": _vm.closeDatasetsList,
                    delete: _vm.deleteDataset,
                    newCatalogue: _vm.addCatalogue,
                    "update-catalogue": _vm.updateCatalogue,
                    "track-db": _vm.trackAll,
                    "new-dataset": _vm.addDataset,
                  },
                })
              : _vm._e(),
            _vm.trackLoader ? _c("TrackLoader") : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-10" },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-end service-table" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              !_vm.exist
                                ? _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: "confirm",
                                            dark: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.newServicePopup = true
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("UPDATE FDSA SERVICE INFO")]
                                  )
                                : _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "warning",
                                            small: "",
                                          },
                                          on: { click: _vm.updateService },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v("EDIT FDSA SERVICE INFO")]
                                  ),
                              _c(
                                "v-tooltip",
                                {
                                  staticClass: "ml-3",
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("mdi-information")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "This information will be made available to end-user/researchers "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "via API call. This will help them easily understand some details "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "about the FDSA Service they atr connected to during their research."
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "Allows to update the publicly available information about the FDSA"
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-simple-table",
                { staticClass: "elevation-6 mt-2 mb-15 service-table" },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v("ID")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.id))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Name")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.name))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Organization")]),
                      _c(
                        "td",
                        _vm._l(
                          _vm.service.organization,
                          function (value, name, index) {
                            return _c("span", { key: index }, [
                              _c("div", [
                                _c("label", { staticClass: "label-form" }, [
                                  _vm._v(_vm._s(name) + ": "),
                                ]),
                                _c("span", { staticClass: "ml-3" }, [
                                  _vm._v(_vm._s(value)),
                                ]),
                              ]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Approved Registries")]),
                      _c(
                        "td",
                        _vm._l(
                          _vm.service.approved_registries,
                          function (ar, index) {
                            return _c("ul", { key: index }, [
                              _c("li", [_vm._v(_vm._s(ar))]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Contact URL")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.contactUrl))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Documentation URL")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.documentationUrl))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Environment")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.environment))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Description")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.description))]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Storage")]),
                      _c(
                        "td",
                        _vm._l(_vm.service.storage, function (st, index) {
                          return _c("ul", { key: index }, [
                            _c("li", [_vm._v(_vm._s(st))]),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Type")]),
                      _c(
                        "td",
                        _vm._l(_vm.service.type, function (value, name, index) {
                          return _c("span", { key: index }, [
                            _c("div", [
                              _c("label", { staticClass: "label-form" }, [
                                _vm._v(" " + _vm._s(name) + ": "),
                              ]),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(value)),
                              ]),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Created")]),
                      _c("td", [
                        _vm.service.createdAt
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.service.createdAt)
                                      .format("D MMMM YYYY - HH:mm:ss")
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Updated")]),
                      _c("td", [
                        _vm.service.updatedAt
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.service.updatedAt)
                                      .format("D MMMM YYYY - HH:mm:ss")
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("tr", [
                      _c("th", [_vm._v("Version")]),
                      _c("td", [_vm._v(_vm._s(_vm.service.version))]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.newServicePopup
            ? _c("NewServiceInfoModal", {
                attrs: { rules: _vm.rules, openModal: _vm.newServicePopup },
                on: {
                  "new-service": _vm.addService,
                  "close-service": _vm.closeAddService,
                },
              })
            : _vm._e(),
          _vm.editServicePopup
            ? _c("EditServiceInfoModal", {
                attrs: {
                  openModal: _vm.editServicePopup,
                  service: _vm.targetSrvc,
                },
                on: {
                  "close-edit": _vm.closeEditService,
                  update: _vm.confirmUpdate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
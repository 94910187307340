<template>
  <v-card class="px-5 pt-5">
    <v-card-text class="px-10">
      <div>
        <v-data-table
          :headers="usersHeaders"
          :items="filteredUsers"
          class="elevation-6 mt-3 mb-3"
          :search="search"
          :items-per-page.sync="pagination.rowsPerPage"
          :server-items-length="totalItems"
          :page.sync="pagination.page"
          @update:page="onPageChange"
          @update:items-per-page="onItemsPerPageChange"
          :footer-props="{
            itemsPerPageOptions: [5, 15, 25, 50],
          }"
          :disable-sort="true"
        >
          <template v-slot:top>
            <div class="search-box">
              <div class="py-3 search-input">
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                  solo
                  dense
                  outlined
                  prepend-inner-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.username }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.uuid }}</td>
              <td class="role-name">
                <span v-if="item.role === 'administrator'">
                  <v-chip class="ma-2" color="secondary" small dark>
                    <v-avatar left>
                      <v-icon>mdi-account-cog</v-icon>
                    </v-avatar>
                    {{ item.role }}
                  </v-chip>
                </span>
                <span v-if="item.role === 'standard'">
                  <v-chip class="ma-2" color="warning" small dark>
                    <v-avatar left>
                      <v-icon>mdi-account</v-icon>
                    </v-avatar>
                    {{ item.role }}
                  </v-chip>
                </span>
                <span v-if="item.role === ''">
                  <v-chip class="ma-2" color="error" small dark>
                    Not Defined
                  </v-chip>
                </span>
              </td>
              <td>{{ moment(item.created_at).format("DD/MMM/YYYY") }}</td>
              <td>
                <span v-if="item.enabled">
                  <v-icon color="green">mdi-check-circle</v-icon>
                </span>
                <span v-else>
                  <v-icon color="red">mdi-close-circle</v-icon>
                </span>
              </td>
              <td>
                <v-menu transition="slide-x-transition" bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                  </template>

                  <v-list>
                    <v-list-item @click="getRoles(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-account-star</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Roles</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.enabled" @click="disableUser(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-close-thick</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Disable</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else @click="enableUser(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Enable</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="resetMfa(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-lock</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-if="item.mfa_enabled"
                        >Reset MFA</v-list-item-title
                      >
                      <v-list-item-title v-else>Reset MFA</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteUser(item)">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <v-btn
              class="mt-5"
              text
              color="primary"
              small
              @click="addUserModal = true"
              ><v-icon>mdi-plus</v-icon> Add a new User</v-btn
            >
          </template>
        </v-data-table>
      </div>
      <!-- Add a new user modal -->
      <AddUserForm
        @add-user="addNewUser"
        @close="closeAddUser"
        :rules="rules"
        :addUserModal="addUserModal"
        v-if="addUserModal"
      />

      <!-- Manage user roles modal -->
      <UserRolesForm
        @update-role="updateRoles"
        @close-roles="closeEditRoles"
        :actualRole="role"
        :showRoles="userRolesPopup"
        :userRolesList="userRoles"
        v-if="userRolesPopup"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import AddUserForm from "@/components/dialogs/AddUserForm.vue";
import UserRolesForm from "@/components/dialogs/UserRolesForm.vue";
export default {
  name: "usersSection",
  components: {
    AddUserForm,
    UserRolesForm,
  },
  props: {
    rules: Object,
    admin_token: String,
  },
  data() {
    return {
      usersList: [],
      usersHeaders: [
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
        { text: "UUID" },
        { text: "Role", value: "role" },
        { text: "Created" },
        { text: "Enabled" },
        { text: "Actions" },
      ],
      addUserModal: false,
      editUserPopup: false,
      enableValues: [
        { label: "Enable", value: true },
        { label: "Disable", value: false },
      ],
      userRolesPopup: false,
      uuidTarget: "",
      userRoles: ["administrator", "standard"],
      finalRole: "",
      role: null,
      search: "",
      editableUser: {},
      pagination: {
        page: 1,
        rowsPerPage: 5,
      },
      totalItems: 0,
    };
  },
  methods: {
    async getUsers() {
      try {
        const res = await axios.get(
          `/api/admin/v1.0/users?page=${this.pagination.page}&page_size=${this.pagination.rowsPerPage}`
        );
        this.usersList = res.data.data.sort((a, b) =>{
          return new Date(b.created_at) - new Date(a.created_at);
        });
        this.totalItems = res.data.total;
        this.$store.commit("setUsers", res.data.data.length);
      } catch (err) {
        console.log(err);
      }
    },
    async addNewUser(user) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.post("/api/admin/v1.0/users", user);
        console.log(res.status);
        this.uuidTarget = res.data.uuid;
        this.updateRoles(1);
        swal("Confirmation", "User added", "success");
        this.addUserModal = false;
        this.getUsers();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }

      this.$store.commit("setLoader", false);
    },
    async disableUser(user) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.delete(`/api/admin/v1.0/users/${user.uuid}`);
        console.log(res.status);
        swal("Confirmation", "User disabled", "success");
        this.getUsers();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async enableUser(user) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.post(
          `/api/admin/v1.0/users/${user.uuid}/enable`
        );
        console.log(res.message);
        swal("Confirmation", "User enabled", "success");
        this.getUsers();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async getRoles(user) {
      this.uuidTarget = user.uuid;
      const allRoles = ["administrator", "standard"];

      try {
        const res = await axios.get(`/api/admin/v1.0/users/${user.uuid}/roles`);
        if (this.userRoles.length > 0) {
          this.userRoles = [];
          this.role = null;
        }
        if (res.data.role.length > 0) {
          this.userRoles.push(res.data.role);
          this.role = 0;
          allRoles.splice(allRoles.indexOf(this.userRoles[0]), 1);
          this.userRoles = this.userRoles.concat(allRoles);
        } else {
          this.userRoles = allRoles.slice();
        }
        this.userRolesPopup = true;
      } catch (err) {
        console.log(err);
      }
    },
    async updateRoles(roleSelected) {
      this.$store.commit("setLoader", true);
      this.finalRole = this.userRoles[roleSelected];

      try {
        const res = await axios.patch(
          `/api/admin/v1.0/users/${this.uuidTarget}/roles`,
          {
            role: this.finalRole,
          }
        );
        this.userRolesPopup = false;
        this.getUsers();
        swal("Confirmation", res.data.message, "success");
      } catch (err) {
        this.userRolesPopup = false;
        console.log(err);
      }

      this.$store.commit("setLoader", false);
    },
    async resetMfa(user) {
      swal({
        title: "Multi-Factor Authentication",
        text: "Are you sure you want to reset this user's MFA?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = axios.post(
              `/api/admin/v1.0/users/${user.uuid}/reset_mfa`
            );
            console.log(res.status);
            swal("Confirmation", "MFA successfully reset", "success");
          } catch (err) {
            console.log(err);
          } finally {
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    deleteUser(user) {
      swal({
        title: "Delete",
        text: "Are you sure you want to delete this user?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          this.$store.commit("setLoader", true);
          try {
            const res = await axios.delete(
              `/api/admin/v1.0/users/hard_delete/${user.uuid}`
            );
            console.log(res.status);
            swal("Confirmation", "User deleted", "success");
          } catch (err) {
            console.log(err);
          } finally {
            this.getUsers();
            this.$store.commit("setLoader", false);
          }
        }
      });
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.getUsers();
    },
    onItemsPerPageChange(rowsPerPage) {
      this.pagination.rowsPerPage = rowsPerPage;
      this.getUsers();
    },
    closeAddUser(val) {
      this.addUserModal = val;
    },
    closeEditUser(val) {
      this.editUserPopup = val;
    },
    closeEditRoles(val) {
      this.userRolesPopup = val;
    },
  },
  mounted() {
    this.getUsers();
  },
  watch: {
    admin_token: function (value) {
      if (value) this.getUsers();
    },
  },
  computed: {
    filteredUsers() {
      return this.usersList.filter((user) => {
        return (
          user.username.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase()) ||
          user.role.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
};
</script>
<style scoped>
.instructions {
  font-style: italic;
  color: gray;
}

.role-name {
  text-transform: capitalize;
}
</style>

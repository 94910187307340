var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "700" },
      model: {
        value: _vm.newDataSourceModal,
        callback: function ($$v) {
          _vm.newDataSourceModal = $$v
        },
        expression: "newDataSourceModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Add a new data source "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-database")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newDtSrcForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database type"),
                          ]),
                          _c("v-select", {
                            attrs: {
                              items: _vm.databaseTypesOptions,
                              outlined: "",
                              solo: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newDataSource.database_type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newDataSource,
                                  "database_type",
                                  $$v
                                )
                              },
                              expression: "newDataSource.database_type",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Name (friendly name)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newDataSource.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.newDataSource, "name", $$v)
                              },
                              expression: "newDataSource.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Host (FQDN or IPs only)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              placeholder: "postgres.db.org",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newDataSource.host,
                              callback: function ($$v) {
                                _vm.$set(_vm.newDataSource, "host", $$v)
                              },
                              expression: "newDataSource.host",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Port"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              solo: "",
                              outlined: "",
                              dense: "",
                              min: "0",
                              rules: [_vm.rules.required],
                              disabled: "",
                            },
                            model: {
                              value: _vm.portNumber,
                              callback: function ($$v) {
                                _vm.portNumber = _vm._n($$v)
                              },
                              expression: "portNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "6", sm: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database Name (case sensitive)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newDataSource.database_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newDataSource,
                                  "database_name",
                                  $$v
                                )
                              },
                              expression: "newDataSource.database_name",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database User (case sensitive)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              autocomplete: "new-username",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.notAllowedUser,
                              ],
                            },
                            model: {
                              value: _vm.newDataSource.database_user,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newDataSource,
                                  "database_user",
                                  $$v
                                )
                              },
                              expression: "newDataSource.database_user",
                            },
                          }),
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Database Password (case sensitive)"),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              autocomplete: "new-password",
                              "append-icon": _vm.showPass
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.showPass ? "text" : "password",
                              rules: [_vm.rules.required],
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.showPass = !_vm.showPass
                              },
                            },
                            model: {
                              value: _vm.newDataSource.database_password,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newDataSource,
                                  "database_password",
                                  $$v
                                )
                              },
                              expression: "newDataSource.database_password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "6" } },
                        [
                          _c("label", { staticClass: "label-form" }, [
                            _vm._v("Description"),
                          ]),
                          _c("v-textarea", {
                            attrs: {
                              solo: "",
                              outlined: "",
                              dense: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newDataSource.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.newDataSource, "description", $$v)
                              },
                              expression: "newDataSource.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "confirm" },
                      on: { click: _vm.testConnection },
                    },
                    [_vm._v("Test")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "confirm", disabled: !_vm.save },
                      on: { click: _vm.addDataSource },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
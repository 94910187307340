<template>
  <v-app-bar app color="black" dark>
    <div class="d-flex flex-end align-center">
      <v-img
        alt="ADDI Logo"
        class="shrink mr-2"
        contain
        src="../assets/new-logo.svg"
        width="120px"
        height="50px"
        transition="scale-transition"
      />
      <v-divider vertical class="ml-5 mr-5 divider-header"></v-divider>
      <v-toolbar-title
        >Federated Data Sharing Appliance - Administration
        Application</v-toolbar-title
      >
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "appHeader",
  data() {
    return {
      signedIn: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.activeUser;
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.signedIn = true;
      }
    },
  },
};
</script>
<style>
.v-toolbar__content{
  display: flex;
  justify-content: space-between;
}
.v-toolbar__title{
  font-size:19px;
}
</style>

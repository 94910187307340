<template>
  <v-dialog v-model="updateDs" persistent width="700">
    <v-card color="#efefef">
      <v-card-title>
        Update data source
        <v-icon color="primary" class="ml-3">mdi-database-edit</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="newDtSrcForm">
        <v-card-text>
          <v-row>
            <v-col md="6" sm="6"
              ><label class="label-form">Database type</label>
              <v-select
                :items="databaseTypesOptions"
                outlined
                v-model="targetDataSource.database_type"
                solo
                dense
                :rules="[rules.required]"
              ></v-select>
              <label class="label-form">Name (Friendly Name)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="targetDataSource.name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col md="6" sm="6">
              <label class="label-form">Host (FQDN Or IPs Only)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="targetDataSource.host"
                :rules="[rules.required]"
              ></v-text-field>
              <label class="label-form">Port</label>
              <v-text-field
                type="number"
                solo
                outlined
                dense
                min="0"
                v-model.number="targetDataSource.port"
                :rules="[rules.required]"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <label class="label-form">Database Name (Case Sensitive)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="targetDataSource.database_name"
                :rules="[rules.required]"
              ></v-text-field>
              <label class="label-form">Database User (Case Sensitive)</label>
              <v-text-field
                solo
                outlined
                dense
                v-model="targetDataSource.database_user"
                :rules="[rules.required, rules.notAllowedUser]"
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <label class="label-form">Description</label>
              <v-textarea
                solo
                outlined
                dense
                v-model="targetDataSource.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="saveChanges">Update</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "UpdateDatasourceModal",
  props: {
    editDs: Object,
    openModal: Boolean,
    rules: Object,
  },

  data() {
    return {
      databaseTypesOptions: ["PostgreSQL"],
      targetDataSource: {},
      updateDs: false,
    };
  },

  created() {
    this.targetDataSource = this.editDs;
    this.updateDs = this.openModal;
  },

  methods: {
    saveChanges() {
      this.$emit("update", this.targetDataSource);
    },
    close() {
      this.updateDs = false;
      this.$emit("close-edit", this.updateDs);
    },
  },
};
</script>

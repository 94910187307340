<template>
  <v-app>
    <appHeader />
    <v-main>
      <router-view />
      <v-dialog v-model="loader" width="350" height="300">
        <v-card class="pa-3">
          <v-card-text class="text-center">
            <p class="loading-text">Loading...</p>
            <v-img
              class="mt-2 mx-auto"
              src="@/assets/Logo-loading2.gif"
              height="50"
              width="50"
            ></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import appHeader from "./components/AppHeader.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  components: {
    appHeader,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logOut"]),
  },
  computed: {
    ...mapState(["loader", "token"]),
  },
};
</script>
<style>
@import "@/assets/css/materialdesignicons.min.css";

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/Lato-Regular.ttf") format("ttf");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto-Regular.ttf") format("ttf");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

#app {
  font-family: "Lato", sans-serif;
}
.titles {
  font-weight: bold;
  color: #102c52;
  font-size: 18px;
}
.v-list-item {
  cursor: pointer;
}
.v-data-table-header th {
  white-space: nowrap;
  text-transform: capitalize;
}
.v-data-table-header tr {
  background-color: rgb(235, 234, 234);
}
.search-box {
  background-color: #f2f3f4;
}
.search-input {
  width: 300px;
}
ul {
  list-style-type: square;
}
.swal-modal .swal-text {
  text-align: center;
}
.label-form {
  font-weight: 700;
  text-transform: capitalize;
}
textarea {
  resize: none;
}
.loading-text {
  font-weight: 700;
  font-size: 25px;
}
</style>

<template>
  <v-dialog v-model="updateReg" persistent width="500">
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-3">mdi-pencil</v-icon>
        Update Registry
      </v-card-title>
      <v-divider></v-divider>
      <v-form lazy-validation ref="editRegForm">
        <v-card-text>
          <label class="label-form" for="address">Registry Address</label>
          <v-text-field
            solo
            dense
            outlined
            v-model="editReg.address"
            id="address"
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form" for="username">Registry Username</label>
          <v-text-field
            solo
            dense
            outlined
            v-model="editReg.username"
            id="username"
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form" for="pass">Registry Password</label>
          <v-text-field
            solo
            dense
            outlined
            v-model="editReg.password"
            id="pass"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" dark @click="update">update</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "UpdateRegistryModal",
  props: {
    openModal: Boolean,
    reg: Object,
    rules:Object
  },
  data() {
    return {
      editReg: {},
      showPass: false,
      updateReg: false,
    };
  },

  created() {
    this.updateReg = this.openModal;
    this.editReg = this.reg;
  },

  methods: {
    close() {
      this.updateReg = false;
      this.$emit("close-reg", this.updateReg);
    },
    update() {
      if (!this.$refs.editRegForm.validate()) {
        return;
      }
      this.editReg.registry_address = this.editReg.address;
      this.$emit("update-reg", this.editReg);
    },
  },
};
</script>

<template>
  <v-dialog v-model="detailsModal" width="800" persistent>
    <v-card color="#efefef">
      <v-card-title>Details</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table class="elevation-2 mt-2 mb-2">
          <tbody>
            <tr>
              <th>Project Code</th>
              <td>{{ darDetails.project_code }}</td>
            </tr>
            <tr>
              <th>Access Level</th>
              <td>
                <span :title="darDetails.scopes">
                  {{ darDetails.access_level }}
                </span>
              </td>
            </tr>
            <tr>
              <th>User</th>
              <td>
                <span
                  v-for="(value, name, index) in darDetails.requester"
                  :key="index"
                >
                  <div>
                    <label class="label-form">{{ name }}</label>
                    <span class="ml-3">{{ value }}</span>
                  </div>
                </span>
              </td>
            </tr>
            <tr>
              <th>Request Date</th>
              <td>
                {{
                  moment(darDetails.created_at).format("D MMMM YYYY - HH:mm:ss")
                }}
              </td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>
                {{ moment(darDetails.end_date).format("D MMMM YYYY - HH:mm:ss") }}
              </td>
            </tr>
            <tr>
              <th>Project Description</th>
              <td>
                <v-textarea
                  flat
                  solo
                  rows="1"
                  auto-grow
                  class="description-box"
                  :value="darDetails.project_description"
                  readonly
                  hide-details
                  full-width
                ></v-textarea>
              </td>
            </tr>
            <tr>
              <th>Purpose</th>
              <td>
                <v-textarea
                  flat
                  solo
                  rows="1"
                  auto-grow
                  class="description-box"
                  :value="darDetails.purpose"
                  readonly
                  hide-details
                  full-width
                ></v-textarea>
              </td>
            </tr>
            <tr>
              <th>Callback URL</th>
              <td>{{ darDetails.callback_url }}</td>
            </tr>
            <tr>
              <th>Dataset Name</th>
              <td>{{ darDetails.dataset_title }}</td>
            </tr>
            <tr>
              <th>Dataset ID</th>
              <td>{{ darDetails.dataset_uuid }}</td>
            </tr>
            <tr>
              <th>Trusted by dataset</th>
              <td>
                <span v-if="darDetails.trusted_dataset">
                  <v-icon color="#0af215">mdi-check</v-icon>
                </span>
                <span v-else>
                  <v-icon color="#f2190a">mdi-close</v-icon>
                </span>
              </td>
            </tr>
            <tr>
              <th>Trusted by project</th>
              <td>
                <span v-if="darDetails.trusted_dar">
                  <v-icon color="#0af215">mdi-check</v-icon>
                </span>
                <span v-else>
                  <v-icon color="#f2190a">mdi-close</v-icon>
                </span>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{{ darDetails.status }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'DarDetailsModal',
    props:{
        darInfo:Object,
        showDetails:Boolean
    },

    data() {
        return {
            darDetails:{},
            detailsModal:false
        };
    },

    created() {
        this.darDetails = this.darInfo;
        this.detailsModal = this.showDetails;
    },

    methods: {
        close(){
            this.detailsModal = false;
            this.$emit('close-details', this.detailsModal)
        }
    },
};
</script>
<style scoped>
.description-box{
  margin-left:-12px; 
  font-size: 0.875rem;
}
</style>
<template>
  <v-dialog v-model="editWHModal" width="400" persistent>
    <v-card>
      <v-card-title> Edit Web Hook </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <label class="label-form" for="url">URL</label>
        <v-text-field solo outlined dense v-model="url" class="mt-1"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Cancel</v-btn>
        <v-btn color="confirm" dark @click="saveChanges">Update</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditWebHookModal",
  props: {
    openModal: Boolean,
    editableUrl: String,
  },
  data() {
    return {
      editWHModal: false,
      url:""
    };
  },
  methods: {
    saveChanges() {
      this.$emit('updateWH', this.url);
    },
    close() {
      this.editWHModal = false;
      this.$emit('close-edit', this.editWHModal);
    },
  },
  created(){
    this.editWHModal = this.openModal;
    this.url = this.editableUrl;
  }
};
</script>

<template>
  <v-dialog v-model="reviewTaskModal" width="500" persistent>
    <v-card color="#efefef">
      <v-card-title
        >Review Task
        <v-icon color="primary" class="ml-3"
          >mdi-briefcase-eye</v-icon
        ></v-card-title
      >
      <v-divider></v-divider>
      <v-form lazy-validation ref="reviewForm">
        <v-card-text>
          <label class="label-form">Outcome</label>
          <v-select
            solo
            outlined
            dense
            v-model="reviewPayload.outcome"
            :items="outcomeOptions"
            :rules="[rules.required]"
          ></v-select>
          <label class="label-form">Reason</label>
          <v-textarea
            outlined
            solo
            cols="60"
            rows="5"
            v-model="reviewPayload.reason"
            :rules="[rules.required]"
          ></v-textarea>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="cancel" @click="close">Cancel</v-btn>
        <v-btn color="confirm" dark @click="reviewCurrentTask">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ReviewTaskModal",
  props: {
    openTaskReviewModal: Boolean,
    rules: Object,
  },
  data() {
    return {
      reviewTaskModal: false,
      outcomeOptions: ["APPROVED", "REJECTED"],
      reviewPayload: {},
    };
  },

  created() {
    this.reviewTaskModal = this.openTaskReviewModal;
  },

  methods: {
    reviewCurrentTask() {
      if (!this.$refs.reviewForm.validate()) {
        return;
      }
      this.$emit("review", this.reviewPayload);
    },
    close() {
      this.reviewTaskModal = false;
      this.$emit("close-review", this.reviewTaskModal);
    },
  },
  watch: {
    reviewTaskModal: function (value) {
      if (!value) {
        this.$refs.reviewForm.reset();
      }
    },
  },
};
</script>

import { render, staticRenderFns } from "./ServiceSection.vue?vue&type=template&id=4dedbec0&scoped=true"
import script from "./ServiceSection.vue?vue&type=script&lang=js"
export * from "./ServiceSection.vue?vue&type=script&lang=js"
import style0 from "./ServiceSection.vue?vue&type=style&index=0&id=4dedbec0&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dedbec0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VIcon,VSimpleTable,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dedbec0')) {
      api.createRecord('4dedbec0', component.options)
    } else {
      api.reload('4dedbec0', component.options)
    }
    module.hot.accept("./ServiceSection.vue?vue&type=template&id=4dedbec0&scoped=true", function () {
      api.rerender('4dedbec0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ServiceSection.vue"
export default component.exports
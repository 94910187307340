var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", persistent: "" },
      model: {
        value: _vm.licenseKeyModal,
        callback: function ($$v) {
          _vm.licenseKeyModal = $$v
        },
        expression: "licenseKeyModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "primary" } },
                [_vm._v("mdi-key")]
              ),
              _c("span", [_vm._v("License key")]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newKeyForm" },
            [
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    staticClass: "mt-5",
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      placeholder: "Paste your key here",
                    },
                    model: {
                      value: _vm.newKey.license_code,
                      callback: function ($$v) {
                        _vm.$set(_vm.newKey, "license_code", $$v)
                      },
                      expression: "newKey.license_code",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.addKey },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700", persistent: "" },
      model: {
        value: _vm.taskHistoryModal,
        callback: function ($$v) {
          _vm.taskHistoryModal = $$v
        },
        expression: "taskHistoryModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(" Task History "),
              _c(
                "v-icon",
                { staticClass: "ml-3", attrs: { color: "primary" } },
                [_vm._v("mdi-briefcase-eye")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "history-container" },
            [
              _c("v-simple-table", { staticClass: "mt-5" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "history-headers" }, [
                      _vm._v("Updated"),
                    ]),
                    _c("th", { staticClass: "history-headers" }, [
                      _vm._v("State"),
                    ]),
                    _c("th", { staticClass: "history-headers" }, [
                      _vm._v("Details"),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.histories, function (history, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(history.updated_at))]),
                      _c("td", [_vm._v(_vm._s(history.state))]),
                      _c("td", [_vm._v(_vm._s(history.details))]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-alert
      v-if="noLicense"
      class="ma-0"
      :color="color"
      dark
      :icon="icon"
      dense
      dismissible
    >
      <span class="alert-message">
        {{ text }}
      </span>
      <span>
        <v-btn
          class="ml-3 red--text"
          x-small
          rounded
          dense
          @click="openModals"
          color="#fff"
          >{{ btnText }}</v-btn
        >
      </span>
      <span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-3" dark v-bind="attrs" v-on="on">
              mdi-information
            </v-icon>
          </template>
          <span>{{ tltmsg }}</span>
        </v-tooltip>
      </span>
    </v-alert>

    <!-- Add License key Modal -->
    <AddNewKeyModal
      @new-key="addLicense"
      @close-key="closeAddKey"
      :openModal="newKeyModal"
      v-if="newKeyModal"
    />

    <!-- Renew License Modal -->
    <ReNewLiceModal
      @renew-lic="renewLicence"
      @close-renew="closeRenewLicense"
      :rules="rules"
      :openModal="reNewLicModal"
      v-if="reNewLicModal"
    />
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import { mapState } from "vuex";
import AddNewKeyModal from "./dialogs/AddNewKeyModal.vue";
import ReNewLiceModal from "./dialogs/ReNewLiceModal.vue";
export default {
  name: "LicenseBox",
  components: {
    AddNewKeyModal,
    ReNewLiceModal,
  },
  data() {
    return {
      noLicense: false,
      newKeyModal: false,
      reNewLicModal: false,
      text: "",
      color: "",
      icon: "",
      btnText: "",
      tltmsg: "",
      rules: {
        required: (value) => !!value || "This is a required field.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    async checkLicense() {
      const lic = axios.create({
        baseURL: `https://${process.env.VUE_APP_FQDN}`,
      });
      try {
        const res = await lic.get("/api/admin/v1.0/check_license");
        if (res.data.remaining_days >= 0 && res.data.remaining_days <= 30) {
          this.noLicense = true;
          this.color = "warning";
          this.icon = "mdi-key-minus";
          this.btnText = "Renew";
          this.tltmsg =
            "Email addi at fdsa.crm@alzheimerdata.org for licensing information.";
          this.text = `Your license will expire in ${res.data.remaining_days} ${
            res.data.remaining_days > 1 ? "days" : "day"
          }`;
        } else if (res.data.remaining_days > 30) {
          this.noLicense = false;
        }
      } catch (err) {
        if (err.response.status == 404) {
          this.text = "No License found!";
          this.noLicense = true;
          this.color = "#a9000e";
          this.icon = "mdi-key-remove";
          this.btnText = "Add";
          this.tltmsg =
            "Email addi at fdsa.crm@alzheimerdata.org for licensing information.";
        }
        if (err.response.status == 400) {
          this.text = "License expired";
          this.noLicense = true;
          this.color = "#a9000e";
          this.icon = "mdi-key-remove";
          this.btnText = "Renew";
          this.tltmsg =
            "Email addi at fdsa.crm@alzheimerdata.org for licensing information.";
        }
        if (err.response.status == 403) {
          this.text = "Licensed expired. Common APIs disabled";
          this.noLicense = true;
          this.color = "#a9000e";
          this.icon = "mdi-key-alert";
          this.btnText = "Renew";
          this.tltmsg =
            "License has been expired more than 30 days. Common APIs deactivated";
        }
      }
    },
    async addLicense(newKey) {
      this.$store.commit("setLoader", true);
      this.newKeyModal = false;
      try {
        const res = await axios.patch("/api/admin/v1.0/add_license", newKey);
        this.noLicense = false;
        swal(
          "Confirmation",
          `License successfully added. Expiration: ${res.data.expiration_date}`,
          "success"
        );
        this.checkLicense();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    openModals() {
      if (this.btnText === "Add") {
        this.newKeyModal = true;
      } else {
        this.reNewLicModal = true;
      }
    },
    async renewLicence(renew) {
      this.$store.commit("setLoader", true);
      this.reNewLicModal = false;
      renew.user_email = this.$store.state.userEmail;

      try {
        const res = await axios.post("/api/admin/v1.0/renew_license", renew);
        if (res.status === 202) {
          swal("Not renewable", res.data.message, "warning");
        } else {
          swal("Confirmation", res.data.message, "success");
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.checkLicense();
        this.$store.commit("setLoader", false);
      }
    },
    closeAddKey(val) {
      this.newKeyModal = val;
    },
    closeRenewLicense(val) {
      this.reNewLicModal = val;
    },
  },
  computed: {
    ...mapState(["activeUserRole"]),
  },
  mounted() {
    this.checkLicense();
  },
};
</script>
<style scoped>
.alert-message {
  font-size: 13px;
}
>>>.v-alert .v-alert__content{
  text-align: center !important;
}
</style>

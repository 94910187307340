<template>
  <div class="d-flex justify-center mt-2">
    <v-card class="token-card" width="100%" height="225">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom max-width="250">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ description }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          solo
          dense
          outlined
          :disabled="editable"
          v-model="lifetime"
          type="number"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="editable" color="confirm" @click="editable = false" dark
          >Edit</v-btn
        >
        <v-btn v-else color="primary" @click="editTokenLifeTime">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  name: "TokenLifetimeSection",

  data() {
    return {
      title: "Token Lifetime (in days)",
      editable: true,
      lifetime: null,
      description: "Specify the lifetime of your token in days",
    };
  },

  mounted() {
    this.getTokenLifeTime();
  },

  methods: {
    async getTokenLifeTime() {
      try {
        const res = await axios.get("/api/admin/v1.0/session_token");
        this.lifetime = res.data.lifetime;
      } catch (err) {
        console.log(err);
      }
    },
    async editTokenLifeTime() {
      if (!this.lifetime) {
        swal("Error", "Lifetime field is required", "error");
        return;
      }
      this.$store.commit("setLoader", true);
      try {
        this.lifetime = parseFloat(this.lifetime);
        const res = await axios.post("/api/admin/v1.0/session_token", {
          lifetime: this.lifetime,
        });
        swal("Confirmation", res.data.message, "success");
        this.editable = true;
        this.getTokenLifeTime();
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
<style>
.token-card {
  margin-bottom: 70px;
}
</style>

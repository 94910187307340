<template>
  <div>
    <ModalIdleVue v-if="isIdle" />
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <button
          v-bind="attrs"
          v-on="on"
          class="image-button"
          @click="support = true"
        >
          <v-img width="60" src="@/assets/support.svg"></v-img>
        </button>
      </template>
      <span>Support</span>
    </v-tooltip>
    <LicenseBox v-if="$store.state.keycloak && allowed" />
    <v-tabs v-model="mainTab" background-color="#e9f2f9">
      <v-tab class="tab" v-if="allowed">Users</v-tab>

      <v-tab class="tab">Data Access Requests</v-tab>

      <v-tab class="tab" v-if="activeUserRole !== 'requester'">Tasks</v-tab>

      <v-tab class="tab" v-if="allowed">Datasources</v-tab>

      <v-tab class="tab" v-if="allowed">Registries</v-tab>

      <v-tab class="tab" v-if="allowed">Settings</v-tab>

      <v-tab class="tab" v-if="allowed">Service Info</v-tab>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            class="ml-auto mr-5"
            v-bind="attrs"
            v-on="on"
            @click="$store.dispatch('logOut')"
            ><v-icon color="primary">mdi-logout</v-icon></v-btn
          >
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-tabs>

    <v-tabs-items v-model="mainTab">
      <v-tab-item v-if="allowed">
        <usersSection :rules="rules" />
      </v-tab-item>
      <v-tab-item>
        <darSection :key="componentKey" :rules="rules" :allowed="allowed" />
      </v-tab-item>
      <v-tab-item v-if="activeUserRole !== 'requester'">
        <tasksSection :rules="rules" :allowed="allowed" />
      </v-tab-item>
      <v-tab-item v-if="allowed">
        <datasourceSection :rules="rules" />
      </v-tab-item>
      <v-tab-item v-if="allowed">
        <registrySection :rules="rules" />
      </v-tab-item>
      <v-tab-item v-if="allowed">
        <settingSection :rules="rules" />
      </v-tab-item>
      <v-tab-item v-if="allowed">
        <serviceSection :rules="rules" />
      </v-tab-item>
    </v-tabs-items>
    <SupportEmailModal
      v-if="support"
      :openModal="support"
      @close-support="closeModal"
      :rules="rules"
    />
    <Footer :rules="rules" />
  </div>
</template>
<script>
import usersSection from "../components/UsersSection.vue";
import tasksSection from "../components/TasksSection.vue";
import datasourceSection from "../components/DatasourceSection.vue";
import registrySection from "../components/RegistrySection.vue";
import serviceSection from "../components/ServiceSection.vue";
import settingSection from "../components/SettingSection.vue";
import darSection from "../components/DarSection.vue";
import ModalIdleVue from "./ModalIdle.vue";
import Footer from "../components/AppFooter.vue";
import SupportEmailModal from "@/components/dialogs/SupportEmailModal.vue";
import LicenseBox from "./LicenseBox.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "mainSection",
  components: {
    usersSection,
    tasksSection,
    datasourceSection,
    registrySection,
    serviceSection,
    settingSection,
    darSection,
    ModalIdleVue,
    Footer,
    SupportEmailModal,
    LicenseBox,
  },
  data() {
    return {
      support: false,
      allowed: false,
      mainTab: null,
      rules: {
        required: (value) => !!value || "This is a required field.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        notAllowedUser: (value) => {
          if (value) {
            const pattern = /^(?:(?!root).)*$/;
            return (
              pattern.test(value.toLowerCase()) ||
              "'root' user not allowed. Please choose another one."
            );
          }
          return true;
        },
      },
      componentKey: 0,
    };
  },
  methods: {
    async getActiveUserRole() {
      try {
        const res = await axios.get(
          `/api/admin/v1.0/users/${this.$store.state.uuid}/roles`
        );
        this.$store.commit("setActiveUserRole", res.data.role);
        if (
          res.data.role === "administrator" ||
          res.data.role === "superadmin"
        ) {
          this.allowed = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    closeModal(val) {
      this.support = val;
    },
  },
  computed: {
    ...mapState(["activeUserRole"]),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  created() {
    this.getActiveUserRole();
  },
  watch: {
    mainTab(val) {
      if (val === 1) {
        this.componentKey += 1;
      }
    },
  },
};
</script>
<style scoped>
.tab {
  text-transform: capitalize !important;
  font-size: 15px;
}
.image-button {
  position: absolute;
  bottom: 60px;
  right: 7px;
  z-index: 1;
}
</style>

<template>
  <div>
    <v-simple-table class="elevation-6 mt-2 mb-15 smtptable">
      <template v-slot:top>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>SMTP Settings</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="#fff"
            outlined
            class="mb-2"
            v-if="smtp.length === 0"
            @click="addSmtpPopup = true"
          >
            Configure SMTP
          </v-btn>
          <v-btn
            color="#fff"
            v-else
            outlined
            class="mb-2"
            @click="openUpdateSMTP()"
          >
            Update
          </v-btn>
        </v-toolbar>
      </template>
      <tbody>
        <tr>
          <th>Display Name</th>
          <td>{{ smtp.mail_display_name }}</td>
        </tr>
        <tr>
          <th>Mail port</th>
          <td>{{ smtp.mail_port }}</td>
        </tr>
        <tr>
          <th>Mail Username</th>
          <td>{{ smtp.mail_username }}</td>
        </tr>
        <tr>
          <th>Mail Server</th>
          <td>{{ smtp.mail_server }}</td>
        </tr>
        <tr>
          <th>Default Sender</th>
          <td>{{ smtp.mail_default_sender }}</td>
        </tr>
        <tr>
          <th>SSL</th>
          <td>
            <span v-if="smtp.mail_use_ssl">
              <v-icon color="#0af215">mdi-check</v-icon>
            </span>
            <span v-else>
              <v-icon color="#f2190a">mdi-close</v-icon>
            </span>
          </td>
        </tr>
        <tr>
          <th>TLS</th>
          <td>
            <span v-if="smtp.mail_use_tls">
              <v-icon color="#0af215">mdi-check</v-icon>
            </span>
            <span v-else>
              <v-icon color="#f2190a">mdi-close</v-icon>
            </span>
          </td>
        </tr>
        <tr>
          <th>Created at</th>
          <td>{{ smtp.created_at }}</td>
        </tr>
        <tr>
          <th>Updated at</th>
          <td>{{ smtp.updated_at }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- Edit SMTP Modal -->
    <EditSMTPModal
      @save-smtp="editSMTPConfig"
      @close-smtp="closeSMTPModal"
      :smtpObject="smtpEditable"
      :openModal="smtpPopup"
      v-if="smtpPopup"
    />
    <!-- Add SMTP Config Modal -->
    <AddSMTPConfig
      @save-smtp="editSMTPConfig"
      @close-smtp="closeAddSMTPModal"
      :openModal="addSmtpPopup"
      v-if="addSmtpPopup"
      :rules="rules"
    />
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import EditSMTPModal from "@/components/dialogs/EditSMTPModal.vue";
import AddSMTPConfig from "./dialogs/AddSMTPConfig.vue";
export default {
  name: "SMTPConfig",
  props: {
    rules: Object,
  },
  components: {
    EditSMTPModal,
    AddSMTPConfig,
  },
  data() {
    return {
      SMTPHeaders: [
        { text: "Display Name" },
        { text: "Mail port" },
        { text: "Mail username" },
        { text: "Mail server" },
        { text: "Default sender" },
        { text: "SSL" },
        { text: "TLS" },
        { text: "Created at" },
        { text: "Updated at" },
        { text: "Actions" },
      ],
      smtp: {},
      smtpEditable: {},
      smtpPopup: false,
      addSmtpPopup: false,
    };
  },

  mounted() {
    this.getSMTPConfig();
  },

  methods: {
    getSMTPConfig() {
      if (this.smtp.length > 0) this.smtp = [];
      axios
        .get("/api/admin/v1.0/smtp_config")
        .then((res) => {
          this.smtp = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openUpdateSMTP() {
      this.smtpEditable = Object.assign({}, this.smtp);
      this.smtpPopup = true;
    },
    async editSMTPConfig(smtpconfig) {
      this.$store.commit("setLoader", true);
      try {
        const res = await axios.patch(
          "/api/admin/v1.0/smtp_config",
          smtpconfig
        );
        swal("confirmation", res.data.message, "success");
        this.getSMTPConfig();
        this.smtpPopup = false;
        this.addSmtpPopup = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    closeSMTPModal(val) {
      this.smtpPopup = val;
    },
    closeAddSMTPModal(val) {
      this.addSmtpPopup = val;
    },
  },
};
</script>
<style scoped>
.smtptable {
  margin-bottom: 50px;
  width: 600px;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-5 ip-boards", attrs: { height: "500" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "primary", dark: "", dense: "" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", "max-width": "250" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-icon",
                            _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                            [_vm._v("mdi-information")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.description))])]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  "prepend-inner-icon": "mdi-magnify",
                  solo: "",
                  outlined: "",
                  dense: "",
                  rounded: "",
                  "hide-details": "",
                  placeholder: "search IP",
                },
                model: {
                  value: _vm.filter,
                  callback: function ($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter",
                },
              }),
              _c("v-divider", { staticClass: "mt-3" }),
              _c(
                "div",
                { staticClass: "whitelist-container" },
                [
                  _vm.data.length > 0
                    ? _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.filteredIps, function (ip, i) {
                          return _c(
                            "v-list-item",
                            { key: i },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v(" mdi-ip-network "),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-content", [
                                _vm._v(" " + _vm._s(ip) + " "),
                              ]),
                              _c(
                                "v-list-item-action",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeIp(ip)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-trash-can-outline")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c("div", { staticClass: "no-ips-text" }, [
                        _c("p", [
                          _vm._v(
                            " There are no IPs associated to this whitelist. You can add IPs using the button below "
                          ),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.newIpModal = true
                    },
                  },
                },
                [_vm._v("Add IP")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.newIpModal
        ? _c("AddIPModal", {
            attrs: { openModal: _vm.newIpModal },
            on: { "new-ip": _vm.addAllowedIP, "close-ip": _vm.closeNewIpModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
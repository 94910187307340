<template>
  <div class="ad-container">
    <v-card width="600" class="reg-form">
      <v-form lazy-validation ref="regForm" @submit.prevent="setUpPassword">
        <v-card-title>Set up password</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <label class="label-form">Email</label>
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-at"
            v-model="userReg.email"
            clearable
            :rules="[rules.email, rules.required]"
          ></v-text-field>
          <label class="label-form">Username</label>
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-account"
            v-model="userReg.username"
            clearable
            :rules="[rules.required]"
          ></v-text-field>
          <label class="label-form">Create Password</label>
          <v-text-field
            outlined
            dense
            :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass1 ? 'text' : 'password'"
            @click:append="showPass1 = !showPass1"
            prepend-inner-icon="mdi-account-key"
            v-model="userReg.password"
            :rules="[rules.required, rules.counter]"
          ></v-text-field>
          <label class="label-form">Confirm Password</label>
          <v-text-field
            outlined
            dense
            :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass2 ? 'text' : 'password'"
            @click:append="showPass2 = !showPass2"
            prepend-inner-icon="mdi-account-key"
            v-model="confirmPassword"
            :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="confirm"
            dark
            width="100"
            type="submit"
            :disabled="btnDisable"
            >Register</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
const setPass = axios.create({
  baseURL: `https://${window.location.host}`,
});
export default {
  name: "RegisterView",
  data: () => ({
    userReg: {
      temporary: false,
      mfa_required: true,
      role: "administrator",
    },
    showPass1: false,
    showPass2: false,
    btnDisable: false,
    confirmPassword: "",
    //Validation Rules
    rules: {
      required: (value) => !!value || "This is a required field",
      counter: (value) =>
        (value &&
          /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
            value
          )) ||
        "Minimum 8 characters. At least 1 capital letter, special character (@$!%*#?&) AND a number",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    confirmPasswordRules: [(v) => !!v || "Password is required"],
  }),
  methods: {
    async setUpPassword() {
      if (this.$refs.regForm.validate() === false) {
        return;
      } else {
        this.btnDisable = true;
        this.$store.commit("setLoader", true);
        this.userReg.username = this.userReg.username.toLowerCase();
        await setPass
          .post("/register", this.userReg)
          .then(() => {
            swal("Confirmation", "User registered", "success").then(() => {
              // Instead of using router push, we used this forced redirection
              // so that KC is initialized.
              window.location.href = "/admin";
            });
          })
          .catch((err) => {
            this.btnDisable = false;
            let message =
              err.response.data.message ||
              err.response.data.errorMessage ||
              err.response.data.error;
            swal("Error", message, "error");
          });

        this.$store.commit("setLoader", false);
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.userReg.password === this.confirmPassword || "Password must match";
    },
  },
};
</script>
<style scoped>
.ad-container {
  background: linear-gradient(135deg, #0978e3 0, #e80097 100%) !important;
  width: 100%;
  height: 100%;
  float: left;
}
.reg-form {
  margin: 100px auto;
}
</style>

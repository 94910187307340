<template>
  <v-dialog width="400" persistent v-model="licenseKeyModal">
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-3">mdi-key</v-icon>
        <span>License key</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="newKeyForm">
        <v-card-text>
          <v-text-field
            class="mt-5"
            solo
            outlined
            dense
            placeholder="Paste your key here"
            v-model="newKey.license_code"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cancel" @click="close">Cancel</v-btn>
          <v-btn color="confirm" @click="addKey" dark>Add</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddNewKeyModal",
  props: {
    openModal: Boolean,
  },
  data() {
    return {
      newKey: {},
      licenseKeyModal: false,
    };
  },

  created() {
    this.licenseKeyModal = this.openModal;
  },

  methods: {
    addKey() {
      this.$emit("new-key", this.newKey);
    },
    close() {
      this.licenseKeyModal = false;
      this.$emit("close-key", this.licenseKeyModal);
    },
  },
  watch: {
    licenseKeyModal: function (value) {
      if (!value) {
        this.$refs.newKeyForm.reset();
      }
    },
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import axios from "axios";
let exp = new Date(new Date().getTime() + 30 * 60 * 1000);

Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "fdsa-app",
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, {
            domain: window.location.host,
            path: "/",
            secure: true,
            expires: exp,
          }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  state: {
    loader: false,
    token: "",
    activeUser: false,
    users: 0,
    uuid: "",
    username: "",
    userEmail:"",
    keycloak: null,
    activeUserRole:null,
    validLicense:null
  },
  mutations: {
    setLoader(state, payload) {
      state.loader = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setActiveUser(state, payload) {
      state.activeUser = payload;
    },
    setUsers(state, payload) {
      state.users = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setKeycloakHandler(state, payload) {
      state.keycloak = payload;
      axios.defaults.headers.common["Authorization"] = `Bearer ${payload.refresh_token}`
    },
    setActiveUserRole(state,payload){
      state.activeUserRole= payload;
    },
    setValidLicense(state, payload){
      state.validLicense = payload;
    },
    setUserEmail(state,payload){
      state.userEmail = payload;
    }

  },
  actions: {
    logOut({ commit }) {
      commit("setToken", "");
      commit("setActiveUser", false);
      commit("setLoader", false);
      commit("setUuid", "");
      commit("setUsername", "");
      this.state.keycloak.logout()
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.keycloak.authenticated;
    },
  },
});

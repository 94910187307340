var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "600", persistent: "" },
      model: {
        value: _vm.addStmpModal,
        callback: function ($$v) {
          _vm.addStmpModal = $$v
        },
        expression: "addStmpModal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-3", attrs: { color: "primary" } },
                [_vm._v("mdi-email")]
              ),
              _vm._v(" Add SMTP Configuration "),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "newSMTPForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "server" } },
                    [_vm._v("Mail server")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "server",
                      rules: [_vm.rules.required],
                      placeholder: "smtp.yourserver.com",
                    },
                    model: {
                      value: _vm.smtpConfig.mail_server,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_server", $$v)
                      },
                      expression: "smtpConfig.mail_server",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "port" } },
                    [_vm._v("Mail Port")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "port",
                      rules: [_vm.rules.required],
                      placeholder: "Example: 587",
                    },
                    model: {
                      value: _vm.smtpConfig.mail_port_str,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_port_str", $$v)
                      },
                      expression: "smtpConfig.mail_port_str",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "sender" } },
                    [_vm._v("Mail default sender")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "sender",
                      rules: [_vm.rules.required],
                      placeholder: "example@email.io",
                    },
                    model: {
                      value: _vm.smtpConfig.mail_default_sender,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_default_sender", $$v)
                      },
                      expression: "smtpConfig.mail_default_sender",
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "label-form",
                      attrs: { for: "mail_username" },
                    },
                    [_vm._v("Mail username")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "mail_username",
                      rules: [_vm.rules.required],
                      placeholder: "Your username",
                    },
                    model: {
                      value: _vm.smtpConfig.mail_username,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_username", $$v)
                      },
                      expression: "smtpConfig.mail_username",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "mail_pass" } },
                    [_vm._v("Mail password")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      id: "mail_pass",
                      "append-icon": _vm.showPass ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass ? "text" : "password",
                      rules: [_vm.rules.required],
                      placeholder: "Your password",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass = !_vm.showPass
                      },
                    },
                    model: {
                      value: _vm.smtpConfig.mail_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_password", $$v)
                      },
                      expression: "smtpConfig.mail_password",
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "label-form",
                      attrs: { for: "display_name" },
                    },
                    [_vm._v("Mail display name")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      id: "display_name",
                      placeholder: "Sender name",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.smtpConfig.mail_display_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.smtpConfig, "mail_display_name", $$v)
                      },
                      expression: "smtpConfig.mail_display_name",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "mail_use" } },
                    [_vm._v("Mail protocol")]
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.protocolOptions,
                      outlined: "",
                      solo: "",
                      dense: "",
                      rules: [_vm.rules.required],
                      placeholder: "Select a protocol",
                    },
                    model: {
                      value: _vm.protocolDecision,
                      callback: function ($$v) {
                        _vm.protocolDecision = $$v
                      },
                      expression: "protocolDecision",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "confirm" },
                      on: { click: _vm.saveChanges },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
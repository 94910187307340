var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.addDtsModal,
        callback: function ($$v) {
          _vm.addDtsModal = $$v
        },
        expression: "addDtsModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c("v-card-title", [
            _c(
              "span",
              [
                _vm._v(" Add Dataset "),
                _c(
                  "v-icon",
                  { staticClass: "ml-3", attrs: { color: "primary" } },
                  [_vm._v("mdi-database-plus")]
                ),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "addDatasetForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Title (friendly name)"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.dataset.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "title", $$v)
                      },
                      expression: "dataset.title",
                    },
                  }),
                  _c("span"),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Schema name"),
                  ]),
                  _c("v-select", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      items: _vm.schemas,
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.dataset.schema_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "schema_name", $$v)
                      },
                      expression: "dataset.schema_name",
                    },
                  }),
                  _c("label", { staticClass: "label-form" }, [
                    _vm._v("Dataset Access Level"),
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { right: "", color: "success" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-1",
                                      attrs: { color: "success", small: "" },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v("mdi-information")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.levelInfo) },
                      }),
                    ]
                  ),
                  _c("v-select", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      dense: "",
                      items: _vm.levels,
                      "item-text": "title",
                      "item-value": "value",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.dataset.dataset_level,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "dataset_level", $$v)
                      },
                      expression: "dataset.dataset_level",
                    },
                  }),
                  _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: {
                      label:
                        "This is a synthetic/test/trusted DB, do not quarantine tasks",
                    },
                    model: {
                      value: _vm.dataset.trusted,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataset, "trusted", $$v)
                      },
                      expression: "dataset.trusted",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "confirm", dark: "" },
                      on: { click: _vm.addNewDataset },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
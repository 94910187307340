var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.sendSupportModal,
        callback: function ($$v) {
          _vm.sendSupportModal = $$v
        },
        expression: "sendSupportModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "primary" } },
                [_vm._v("mdi-face-agent")]
              ),
              _vm._v(" Support request "),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-form",
            { ref: "supportForm", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "subject" } },
                    [_vm._v("Subject")]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      solo: "",
                      dense: "",
                      outlined: "",
                      placeholder: "Issue",
                      rules: [_vm.rules.required],
                    },
                    model: {
                      value: _vm.bodyMessage.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.bodyMessage, "subject", $$v)
                      },
                      expression: "bodyMessage.subject",
                    },
                  }),
                  _c(
                    "label",
                    { staticClass: "label-form", attrs: { for: "subject" } },
                    [_vm._v("Message")]
                  ),
                  _c("v-textarea", {
                    staticClass: "message-body",
                    attrs: {
                      outlined: "",
                      solo: "",
                      rules: [_vm.rules.required],
                      placeholder: "Describe your issue here...",
                    },
                    model: {
                      value: _vm.bodyMessage.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.bodyMessage, "message", $$v)
                      },
                      expression: "bodyMessage.message",
                    },
                  }),
                  _c("v-switch", {
                    attrs: { color: "primary", label: "Send logs" },
                    model: {
                      value: _vm.logs,
                      callback: function ($$v) {
                        _vm.logs = $$v
                      },
                      expression: "logs",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "cancel" }, on: { click: _vm.close } },
                    [_vm._v("cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "confirm" },
                      on: { click: _vm.sendSupportEmail },
                    },
                    [_vm._v("Send")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
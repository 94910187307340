var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "" },
      model: {
        value: _vm.detailsModal,
        callback: function ($$v) {
          _vm.detailsModal = $$v
        },
        expression: "detailsModal",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#efefef" } },
        [
          _c("v-card-title", [_vm._v("Details")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", { staticClass: "elevation-2 mt-2 mb-2" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Project Code")]),
                    _c("td", [_vm._v(_vm._s(_vm.darDetails.project_code))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Access Level")]),
                    _c("td", [
                      _c("span", { attrs: { title: _vm.darDetails.scopes } }, [
                        _vm._v(" " + _vm._s(_vm.darDetails.access_level) + " "),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("User")]),
                    _c(
                      "td",
                      _vm._l(
                        _vm.darDetails.requester,
                        function (value, name, index) {
                          return _c("span", { key: index }, [
                            _c("div", [
                              _c("label", { staticClass: "label-form" }, [
                                _vm._v(_vm._s(name)),
                              ]),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(_vm._s(value)),
                              ]),
                            ]),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Request Date")]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(_vm.darDetails.created_at)
                              .format("D MMMM YYYY - HH:mm:ss")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("End Date")]),
                    _c("td", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm
                              .moment(_vm.darDetails.end_date)
                              .format("D MMMM YYYY - HH:mm:ss")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Project Description")]),
                    _c(
                      "td",
                      [
                        _c("v-textarea", {
                          staticClass: "description-box",
                          attrs: {
                            flat: "",
                            solo: "",
                            rows: "1",
                            "auto-grow": "",
                            value: _vm.darDetails.project_description,
                            readonly: "",
                            "hide-details": "",
                            "full-width": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Purpose")]),
                    _c(
                      "td",
                      [
                        _c("v-textarea", {
                          staticClass: "description-box",
                          attrs: {
                            flat: "",
                            solo: "",
                            rows: "1",
                            "auto-grow": "",
                            value: _vm.darDetails.purpose,
                            readonly: "",
                            "hide-details": "",
                            "full-width": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Callback URL")]),
                    _c("td", [_vm._v(_vm._s(_vm.darDetails.callback_url))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Dataset Name")]),
                    _c("td", [_vm._v(_vm._s(_vm.darDetails.dataset_title))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Dataset ID")]),
                    _c("td", [_vm._v(_vm._s(_vm.darDetails.dataset_uuid))]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Trusted by dataset")]),
                    _c("td", [
                      _vm.darDetails.trusted_dataset
                        ? _c(
                            "span",
                            [
                              _c("v-icon", { attrs: { color: "#0af215" } }, [
                                _vm._v("mdi-check"),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c("v-icon", { attrs: { color: "#f2190a" } }, [
                                _vm._v("mdi-close"),
                              ]),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Trusted by project")]),
                    _c("td", [
                      _vm.darDetails.trusted_dar
                        ? _c(
                            "span",
                            [
                              _c("v-icon", { attrs: { color: "#0af215" } }, [
                                _vm._v("mdi-check"),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "span",
                            [
                              _c("v-icon", { attrs: { color: "#f2190a" } }, [
                                _vm._v("mdi-close"),
                              ]),
                            ],
                            1
                          ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Status")]),
                    _c("td", [_vm._v(_vm._s(_vm.darDetails.status))]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "cancel" }, on: { click: _vm.close } },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
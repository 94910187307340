var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "px-5 pt-5" },
    [
      _c(
        "v-card-text",
        { staticClass: "px-10" },
        [
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "elevation-6 mt-3 mb-3",
                attrs: {
                  headers: _vm.usersHeaders,
                  items: _vm.filteredUsers,
                  search: _vm.search,
                  "items-per-page": _vm.pagination.rowsPerPage,
                  "server-items-length": _vm.totalItems,
                  page: _vm.pagination.page,
                  "footer-props": {
                    itemsPerPageOptions: [5, 15, 25, 50],
                  },
                  "disable-sort": true,
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                  },
                  "update:items-per-page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "rowsPerPage", $event)
                    },
                    _vm.onItemsPerPageChange,
                  ],
                  "update:page": [
                    function ($event) {
                      return _vm.$set(_vm.pagination, "page", $event)
                    },
                    _vm.onPageChange,
                  ],
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "search-box" }, [
                          _c(
                            "div",
                            { staticClass: "py-3 search-input" },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-4",
                                attrs: {
                                  label: "Search",
                                  solo: "",
                                  dense: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("v-divider"),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.username))]),
                          _c("td", [_vm._v(_vm._s(item.email))]),
                          _c("td", [_vm._v(_vm._s(item.uuid))]),
                          _c("td", { staticClass: "role-name" }, [
                            item.role === "administrator"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { left: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-account-cog"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" " + _vm._s(item.role) + " "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.role === "standard"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "warning",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { left: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-account"),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" " + _vm._s(item.role) + " "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.role === ""
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "error",
                                          small: "",
                                          dark: "",
                                        },
                                      },
                                      [_vm._v(" Not Defined ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(item.created_at)
                                  .format("DD/MMM/YYYY")
                              )
                            ),
                          ]),
                          _c("td", [
                            item.enabled
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v("mdi-check-circle")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-close-circle"),
                                    ]),
                                  ],
                                  1
                                ),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    transition: "slide-x-transition",
                                    bottom: "",
                                    right: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.getRoles(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-account-star"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Roles"),
                                          ]),
                                        ],
                                        1
                                      ),
                                      item.enabled
                                        ? _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.disableUser(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-close-thick"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v("Disable"),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.enableUser(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-check"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _c("v-list-item-title", [
                                                _vm._v("Enable"),
                                              ]),
                                            ],
                                            1
                                          ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetMfa(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-lock"),
                                              ]),
                                            ],
                                            1
                                          ),
                                          item.mfa_enabled
                                            ? _c("v-list-item-title", [
                                                _vm._v("Reset MFA"),
                                              ])
                                            : _c("v-list-item-title", [
                                                _vm._v("Reset MFA"),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteUser(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "error" } },
                                                [_vm._v("mdi-delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Delete"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-5",
                            attrs: { text: "", color: "primary", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.addUserModal = true
                              },
                            },
                          },
                          [
                            _c("v-icon", [_vm._v("mdi-plus")]),
                            _vm._v(" Add a new User"),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.addUserModal
            ? _c("AddUserForm", {
                attrs: { rules: _vm.rules, addUserModal: _vm.addUserModal },
                on: { "add-user": _vm.addNewUser, close: _vm.closeAddUser },
              })
            : _vm._e(),
          _vm.userRolesPopup
            ? _c("UserRolesForm", {
                attrs: {
                  actualRole: _vm.role,
                  showRoles: _vm.userRolesPopup,
                  userRolesList: _vm.userRoles,
                },
                on: {
                  "update-role": _vm.updateRoles,
                  "close-roles": _vm.closeEditRoles,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
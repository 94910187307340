import { render, staticRenderFns } from "./TasksSection.vue?vue&type=template&id=8869bfb2&scoped=true"
import script from "./TasksSection.vue?vue&type=script&lang=js"
export * from "./TasksSection.vue?vue&type=script&lang=js"
import style0 from "./TasksSection.vue?vue&type=style&index=0&id=8869bfb2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8869bfb2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VChip,VDataTable,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMenu,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8869bfb2')) {
      api.createRecord('8869bfb2', component.options)
    } else {
      api.reload('8869bfb2', component.options)
    }
    module.hot.accept("./TasksSection.vue?vue&type=template&id=8869bfb2&scoped=true", function () {
      api.rerender('8869bfb2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TasksSection.vue"
export default component.exports